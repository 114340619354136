import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from '../../../services/school.service';
import { School, SchoolLocation, SchoolTypes } from '../../../types/school';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-school-onboarding',
  templateUrl: './school-onboarding.component.html',
  styleUrls: ['./school-onboarding.component.scss'],
})
export class SchoolOnboardingComponent implements OnInit {
  readonly maxLocationCount = 10;

  id: string;
  school$: Observable<School>;
  isLoading = false;
  schoolTypes = SchoolTypes;
  schoolTypeControl = new FormControl<(typeof SchoolTypes)[number] | null>(
    null,
    [Validators.required]
  );

  isActionButtonDisabled() {
    return this.isLoading || !this.isValid();
  }

  schoolName = new FormControl('', [Validators.required]);
  locations: {
    loading: boolean;
    field: FormControl<string | null>;
    value: SchoolLocation;
  }[] = [];

  constructor(
    private schoolService: SchoolService,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.schoolName.setValue(school?.name || '');
      this.schoolTypeControl.setValue(school?.type || null);
      if (school.locations && school.locations?.length > 0) {
        this.locations =
          school.locations
            ?.map(l => {
              const location = {
                field: new FormControl(l.name || '', {
                  validators: [Validators.required],
                  updateOn: 'blur',
                }),
                loading: false,
                value: { ...l },
              };

              location.field.valueChanges.subscribe(value => {
                location.value = { ...location.value, name: value || '' };
              });

              return location;
            })
            .sort((a, b) => (a.value?.order || 0) - (b.value?.order || 0)) ||
          [];
      }
    });
  }

  addLocation() {
    const location = {
      loading: false,
      field: new FormControl('', [Validators.required]),
      value: { name: '', isRoot: false, isPublic: false },
    };

    location.field.valueChanges.subscribe(value => {
      location.value = { ...location.value, name: value || '' };
    });

    this.locations.push(location);
  }

  isValid() {
    return (
      this.schoolName.valid &&
      this.schoolTypeControl.valid &&
      this.locations.every(l => l.field.valid && !l.loading)
    );
  }

  getLocationId(school: School, index: number) {
    return school.locations?.[index]?.id || null;
  }

  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.locations, event.previousIndex, event.currentIndex);
  }

  async save(currentSchool: School) {
    const schoolType = this.schoolTypeControl
      .value as (typeof SchoolTypes)[number];
    this.isLoading = true;
    const schoolName = this.schoolName.value as string;
    const locations = this.locations.map(l => l.value);

    const school: Partial<School> = {
      name: schoolName,
      type: schoolType,
      locations: locations.map(
        (location, index) =>
          ({
            name: location?.name || '',
            id: location?.id || this.getLocationId(currentSchool, index),
            isRoot: location?.isRoot || false,
            isPublic: location?.isPublic || false,
            order: location?.isRoot ? 0 : index + 1,
          }) as SchoolLocation
      ),
    };

    this.schoolService.update(this.id, school).subscribe(() => {
      this.schoolService.updateSchoolLocations(this.id).subscribe({
        next: async () => {
          await this.router.navigate(['/my-schools']);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    });
  }

  removeLocation(index: number) {
    const location = this.locations[index];
    const id = location?.value?.id;
    if (!id) {
      // this location has not been saved yet
      this.locations.splice(index, 1);
      return;
    }

    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: {
          title: 'Standort löschen',
          text: 'Dieser Standort wird unwiderruflich gelöscht. Bist du sicher, dass du das möchtest?',
          confirmText: 'Ja, löschen',
          buttonColor: 'warn',
        },
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          location.loading = true;
          this.schoolService.delete(id, this.id).subscribe(() => {
            this.locations.splice(index, 1);
            this.notificationService.success(
              `${location.value?.name} wurde gelöscht`
            );
          });
        }
      });
  }
}
