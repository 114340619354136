import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-highlight-list-selection',
  templateUrl: './highlight-list-selection.component.html',
  styleUrl: './highlight-list-selection.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HighlightListSelectionComponent),
      multi: true,
    },
  ],
})
export class HighlightListSelectionComponent
  implements ControlValueAccessor, OnInit
{
  @Input() suggestions: string[] = [];
  selectedHighlights: string[] = [];
  disabled = false;
  iconColor = 'secondary';
  highlightControl = new FormControl('');

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public propagateChange = (_suggestions: string[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public propagateTouch = () => {};

  ngOnInit(): void {
    this.highlightControl.valueChanges.subscribe(value => {
      this.iconColor = value && value.length > 0 ? 'primary' : 'secondary';
    });
  }

  writeValue(suggestions: string[]): void {
    if (suggestions && suggestions.length > 0) {
      this.selectedHighlights = suggestions;
      this.propagateChange([...suggestions]);
    } else {
      this.propagateChange([...this.suggestions]);
    }
  }

  registerOnChange(fn: (_suggestions: string[]) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  add(suggestion: string | null, triggeredByControl = false): void {
    if (suggestion && !this.selectedHighlights.includes(suggestion)) {
      this.selectedHighlights.push(suggestion);
      this.propagateChange([...this.selectedHighlights]);
    }
    if (triggeredByControl) {
      this.highlightControl.setValue('');
    }
  }

  remove(highlight: string) {
    this.selectedHighlights = this.selectedHighlights.filter(
      suggestion => suggestion !== highlight
    );
    this.propagateChange([...this.selectedHighlights]);
  }
}
