@if (!showEmptyCard) {
  <div class="flex flex-col justify-between">
    <div class="flex justify-between">
      <strong
        class="font-bold text-2xl md:text-5xl overflow-visible whitespace-nowrap">
        Das bieten wir
      </strong>
      @if (isOwner) {
        <app-edit-button
          class="pl-4"
          routerLink="edit/weoffer"></app-edit-button>
      }
    </div>
    <app-highlight-item-list
      class="pt-4"
      [highlights]="school.whatWeOfferHighlights" />
    <app-rich-text-view
      class="pt-4"
      [html]="school.whatWeOffer_html"
      tailwindCssLineClamp="line-clamp-[17]"></app-rich-text-view>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Das bieten wir"
    subtitle="Glänze hier mit euren Standortvorteilen."
    description="Liste hier möglichst alle Eigenschaften auf, welche diesen Standort zu etwas Tollem machen."
    link="edit/weoffer"
    [fullWidthAlways]="true"></app-empty-card>
}
