import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  getDoc,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { SearchIndex } from 'algoliasearch';
import { from, map, Observable, tap, zip } from 'rxjs';
import { searchClient } from '../core/algolia';
import { PermissionsService } from './permissions.service';
import { SchoolPermission } from '../types/permissions';
import { CheckoutRequest } from '../types/subscription';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { pushIfNotExists } from '../core/helpers';
import { School, SchoolLevels, SchoolLevelDescriptions } from '../types/school';

type FacetFilters =
  | string
  | readonly string[]
  | readonly (string | readonly string[])[]
  | undefined;
type AroundRadius = number | 'all' | undefined;
type AroundLatLng = string | undefined;

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  private index: SearchIndex;
  private updateSchoolLocationsFunc: (data: {
    schoolId: string;
  }) => Observable<void>;
  private updateSchoolContactsFunc: (
    data: SchoolPermission
  ) => Observable<void>;
  private createSchoolFunc: (data: { code: string }) => Observable<void>;
  private purchaseSubscriptionFunc: (data: {
    request: CheckoutRequest;
  }) => Observable<void>;
  private deleteSchoolFunc: (data: { schoolId: string }) => Observable<void>;

  constructor(
    private firestore: Firestore,
    private permissionsService: PermissionsService,
    private analytics: Analytics,
    functions: Functions
  ) {
    this.index = searchClient.initIndex('schools');
    this.updateSchoolLocationsFunc = httpsCallableData(
      functions,
      'updateschoollocations',
      {}
    );
    this.deleteSchoolFunc = httpsCallableData(functions, 'deleteschool', {});
    this.updateSchoolContactsFunc = httpsCallableData(
      functions,
      'updateschoolcontacts',
      {}
    );
    this.createSchoolFunc = httpsCallableData(functions, 'createschool', {});
    this.purchaseSubscriptionFunc = httpsCallableData(
      functions,
      'purchasesubscription',
      {}
    );
  }

  search(
    query: string,
    options: {
      facetFilters?: FacetFilters;
      aroundRadius?: AroundRadius;
      aroundLatLng?: AroundLatLng;
      page?: number;
      hitsPerPage?: number;
      filters?: string;
      maxFacetHits?: number;
    }
  ) {
    return this.index.search<School>(query, { ...options });
  }

  getById(id: string) {
    const docRef = doc(this.firestore, `schools/${id}`);
    return from(getDoc(docRef)).pipe(map(d => d.data() as School));
  }

  getSchoolsByIds(
    schoolIds: string[],
    onlyPublic = false
  ): Observable<School[]> {
    let colRef;
    if (onlyPublic) {
      colRef = query(
        collection(this.firestore, 'schools'),
        where('id', 'in', schoolIds),
        where('isPublic', '==', true)
      );
    } else {
      colRef = query(
        collection(this.firestore, 'schools'),
        where('id', 'in', schoolIds)
      );
    }
    return collectionData(colRef, { idField: 'id' }) as Observable<School[]>;
  }

  getMy(): Observable<School[]> {
    const permissions = this.permissionsService.getPermissions();
    const schoolIds = permissions.map(p => p.schoolId);

    const chunkSize = 30;
    const schoolIdChunks = [];
    for (let i = 0; i < schoolIds.length; i += chunkSize) {
      schoolIdChunks.push(schoolIds.slice(i, i + chunkSize));
    }

    const observables = schoolIdChunks.map(chunk => {
      const colRef = query(
        collection(this.firestore, 'schools'),
        where('id', 'in', chunk)
      );
      return collectionData(colRef, { idField: 'id' }) as Observable<School[]>;
    });

    return zip(observables).pipe(map(results => results.flat()));
  }

  async updateAsync(id: string, school: Partial<School>) {
    const docRef = doc(this.firestore, `schools/${id}`);
    await updateDoc(docRef, school);
  }

  updateContacts(rootId: string, data: SchoolPermission) {
    data.schoolId = rootId;
    return this.updateSchoolContactsFunc(data);
  }

  update(id: string, school: Partial<School>) {
    const docRef = doc(this.firestore, `schools/${id}`);
    return from(updateDoc(docRef, school));
  }

  delete(schoolId: string, rootId: string) {
    return this.deleteSchoolFunc({ schoolId }).pipe(
      tap(_ => this.updateSchoolLocations(rootId))
    );
  }

  publish(school: School) {
    const docRef = doc(this.firestore, `schools/${school.id}`);
    return from(
      updateDoc(docRef, {
        isPublic: true,
        modifiedOn: new Date().toISOString(),
      })
    ).pipe(
      map(_ => ({ ...school, isPublic: true }) as School),
      tap(_ => this.updateSchoolLocations(school.id!))
    );
  }

  updateSchoolLocations(schoolId: string) {
    return this.updateSchoolLocationsFunc({ schoolId });
  }

  create(code: string) {
    return this.createSchoolFunc({ code });
  }

  purchaseSubscription(request: CheckoutRequest) {
    let value = 0;
    switch (request.subscriptionType) {
      case 'SCHOOL_S':
        value = 80;
        break;
      case 'SCHOOL_L':
        value = 110;
        break;
      case 'SCHOOL_CUSTOM':
        value = 1000;
        break;
      default:
        value = 0;
    }

    logEvent<string>(this.analytics, 'purchase', {
      value,
      currency: 'CHF',
      items: [request.subscriptionType],
    });

    return this.purchaseSubscriptionFunc({ request });
  }

  getLatest(count = 3) {
    const colRef = query(
      collection(this.firestore, 'schools'),
      where('isPublic', '==', true),
      where('isRoot', '==', true),
      orderBy('modifiedOn', 'desc'),
      limit(count)
    );
    return collectionData(colRef, { idField: 'id' }) as Observable<School[]>;
  }

  getZyklusLevelsFilter(levels: (typeof SchoolLevels)[number][]) {
    if (levels.includes('Zyklus I')) {
      pushIfNotExists(levels, 'Kindergarten');
      pushIfNotExists(levels, 'Basisstufe');
      pushIfNotExists(levels, 'Unterstufe');
    }

    if (levels.includes('Zyklus II')) {
      pushIfNotExists(levels, 'Mittelstufe');
      pushIfNotExists(levels, 'Primarstufe');
    }

    if (levels.includes('Zyklus III')) {
      pushIfNotExists(levels, 'Sekundarstufe I');
    }

    if (levels.includes('Kindergarten')) {
      pushIfNotExists(levels, 'Basisstufe');
      pushIfNotExists(levels, 'Zyklus I');
    }

    if (levels.includes('Unterstufe')) {
      pushIfNotExists(levels, 'Basisstufe');
      pushIfNotExists(levels, 'Zyklus I');
    }

    if (levels.includes('Mittelstufe')) {
      pushIfNotExists(levels, 'Zyklus II');
    }

    if (levels.includes('Sekundarstufe I')) {
      pushIfNotExists(levels, 'Zyklus III');
    }

    return levels;
  }

  getLevelsOrder(levels: (typeof SchoolLevels)[number][]) {
    const levelsCopy = [...levels] as string[];
    if (
      levelsCopy.includes('Unterstufe') &&
      levelsCopy.includes('Mittelstufe')
    ) {
      levelsCopy.splice(levelsCopy.indexOf('Unterstufe'), 1);
      levelsCopy.splice(levelsCopy.indexOf('Mittelstufe'), 1, 'Primarstufe');
    }

    const order = [
      'Zyklus I',
      'Zyklus II',
      'Zyklus III',
      'Kindergarten',
      'Basisstufe',
      'Unterstufe',
      'Mittelstufe',
      'Primarstufe',
      'Sekundarstufe I',
      'Sekundarstufe II',
    ];

    const orderedLevels = levelsCopy.sort(
      (a, b) => order.indexOf(a) - order.indexOf(b)
    ) as string[];

    return orderedLevels;
  }

  getLevelsTitle(
    levels: (typeof SchoolLevels)[number][],
    schoolLevelDescriptions: (typeof SchoolLevelDescriptions)[number][]
  ) {
    const orderedLevels = this.getLevelsOrder(levels);
    const post =
      schoolLevelDescriptions && schoolLevelDescriptions.length > 0
        ? ` - ${schoolLevelDescriptions.join(' | ')}`
        : '';

    return `${orderedLevels.join(' | ')}${post}`;
  }
}
