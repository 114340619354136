<div class="grid grid-cols-12 md:gap-4 w-full">
  <div
    class="lg:col-start-3 col-span-12 lg:col-span-8 border-dot rounded-[0.75rem] md:rounded-[1.35rem] p-10">
    <div class="flex flex-col gap-8">
      <div>
        <h2 class="font-bold text-2xl">Finde die passende Fachkraft</h2>
        <span class="text-sm font-very-bright-color">
          Je mehr Zusatzangaben, desto höher die Chance, passende Fachkräfte
          anzusprechen. Dein Vorteil: Jedes Inserat ist mit dem Schulprofil
          verknüpft!
        </span>
      </div>
      <div class="flex flex-col xl:flex-row xl:gap-8">
        <div class="flex flex-col text-sm gap-2 pb-6 xl:pb-0">
          <strong class="text-base font-bold">Pflichtangaben</strong>
          <app-progress-card-item
            text="Berufsbezeichnung*"
            link="edit/function"
            [success]="hasFunction"></app-progress-card-item>
          <app-progress-card-item
            text="Schulstufe*"
            link="edit/schoollevels"
            [success]="hasSchoolLevels"></app-progress-card-item>
          <app-progress-card-item
            text="Pensum*"
            link="edit/workload"
            [success]="hasWorkload"></app-progress-card-item>
        </div>

        <div class="flex flex-col text-sm gap-2 pb-2 xl:pb-0">
          <strong class="text-base font-bold">Zusätzliche Angaben</strong>
          <app-progress-card-item
            text="Anforderungen"
            link="edit/requirements"
            [success]="hasRequirements"></app-progress-card-item>
          @if (job.isFullTimeJob) {
            <app-progress-card-item
              text="Antrittsdatum"
              link="edit/date"
              [success]="hasDurationFrom"></app-progress-card-item>
          }
          @if (!job.isFullTimeJob) {
            <app-progress-card-item
              text="Zeitraum"
              link="edit/duration"
              [success]="hasDurationFrom"></app-progress-card-item>
          }
          @if (job.isFullTimeJob) {
            <app-progress-card-item
              text="Bewerbungsfrist"
              link="edit/deadline"
              [success]="applicationEndDate"></app-progress-card-item>
          }
          <app-progress-card-item
            text="Arbeitstage"
            [success]="hasWorkdayMatrix"
            [scrollTargetId]="'workload-matrix'"></app-progress-card-item>
        </div>

        <div class="flex flex-col text-sm gap-2">
          <strong class="hidden xl:block text-base font-bold">&nbsp;</strong>
          <app-progress-card-item
            text="Über uns"
            link="edit/about"
            [success]="hasAbout"></app-progress-card-item>
          <app-progress-card-item
            text="Das bieten wir"
            link="edit/weoffer"
            [success]="hasWeOffer"></app-progress-card-item>
          <app-progress-card-item
            text="Deine Aufgaben"
            link="edit/tasks"
            [success]="hasTasks"></app-progress-card-item>
          <app-progress-card-item
            text="Das bringst du mit"
            link="edit/skills"
            [success]="hasSkills"></app-progress-card-item>
        </div>
      </div>
      <div class="flex flex-col md:flex-row rounded-2xl p-8 bg-box">
        <p class="self-center text-base md:pr-8">
          *Diese Mindestangaben sind notwendig, damit du Stellen ausschreiben
          kannst.
        </p>
        <app-action-button
          class="self-center"
          [disabled]="!canPublish"
          (onClick)="onPublish()"
          text="Veröffentlichen" />
      </div>
    </div>
  </div>
</div>
