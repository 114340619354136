import { Component } from '@angular/core';
import { JobService } from '../../../services/job.service';
import { Job } from '../../../types/job';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { SchoolService } from '../../../services/school.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-schools-jobs',
  templateUrl: './schools-jobs.component.html',
  styleUrls: ['./schools-jobs.component.scss'],
})
export class SchoolsJobsComponent {
  showArchived = false;
  allJobs: Job[] | undefined = undefined;
  filteredArchivedJobs: Job[] | undefined = undefined;
  archiveFilterControl = new FormControl<'startDate' | 'jobName'>('startDate');
  archivedSortOrder: 'asc' | 'desc' = 'desc';

  constructor(
    route: ActivatedRoute,
    jobService: JobService,
    schoolService: SchoolService,
    breadcrumbService: BreadcrumbService
  ) {
    route.data.subscribe(data => {
      this.showArchived = data['archived'];
    });

    route.paramMap.subscribe(params => {
      const schoolRootId = params.get('id') as string;

      schoolService.getById(schoolRootId).subscribe(school => {
        const breadcrumbs = [
          { text: 'Meine Schule', link: 'my-schools' },
          { text: `HR-Tool ${school.name}` },
        ];

        if (this.showArchived) {
          breadcrumbs.push({ text: 'Archiv' });
        }

        breadcrumbService.set(breadcrumbs);
      });

      jobService.getBySchoolRoot(schoolRootId).subscribe(jobs => {
        this.allJobs = jobs;
        this.filteredArchivedJobs = this.filterAndSortArchivedJobs();
      });

      this.archiveFilterControl.valueChanges.subscribe(_ => {
        this.filteredArchivedJobs = this.filterAndSortArchivedJobs();
      });
    });
  }

  filterPublicJobs() {
    return this.allJobs?.filter(job => job.isPublic && !job.isClosed);
  }

  filterDraftJobs() {
    return this.allJobs?.filter(job => !job.isPublic && !job.isClosed);
  }

  filterAndSortArchivedJobs() {
    const jobsFiltered = this.allJobs?.filter(
      job => !job.isPublic && job.isClosed
    );
    const sortCriteria = this.archiveFilterControl.value;

    if (!sortCriteria) {
      return jobsFiltered;
    }

    return jobsFiltered?.sort((a, b) => {
      switch (sortCriteria) {
        case 'startDate': {
          const aStartDate = this.getStartDate(a) ?? 0;
          const bStartDate = this.getStartDate(b) ?? 0;
          return this.archivedSortOrder === 'asc'
            ? aStartDate - bStartDate
            : bStartDate - aStartDate;
        }
        case 'jobName': {
          const aFunction = a.function ?? '';
          const bFunction = b.function ?? '';
          return this.archivedSortOrder === 'asc'
            ? aFunction.localeCompare(bFunction)
            : bFunction.localeCompare(aFunction);
        }
        default:
          return 0;
      }
    });
  }

  switchArchiveSortOrder() {
    this.archivedSortOrder = this.archivedSortOrder === 'asc' ? 'desc' : 'asc';
    this.filterAndSortArchivedJobs();
  }

  private getStartDate(job: Job) {
    return job.isDurationStartNow ? job.publishedOnUnix : job.durationFromUnix;
  }
}
