import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  getDoc,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Application, ApplicationRequest } from '../types/application';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable, from, map, mergeMap } from 'rxjs';
import { ProfileService } from './profile.service';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private applyJobFunc: (data: {
    request: ApplicationRequest;
  }) => Observable<void>;
  private withdrawJobFunc: (data: {
    applicationId: string;
  }) => Observable<void>;
  private rejectJobFunc: (data: {
    applicationId: string;
    text: string;
  }) => Observable<void>;

  constructor(
    private firestore: Firestore,
    private profileService: ProfileService,
    private analytics: Analytics,
    functions: Functions
  ) {
    this.applyJobFunc = httpsCallableData(functions, 'applyjob', {});
    this.withdrawJobFunc = httpsCallableData(
      functions,
      'withdrawapplication',
      {}
    );
    this.rejectJobFunc = httpsCallableData(functions, 'rejectapplication', {});
  }

  apply(request: ApplicationRequest) {
    logEvent(this.analytics, 'application_apply', { jobId: request.job.id });
    return this.applyJobFunc({ request });
  }

  withdraw(applicationId: string) {
    logEvent(this.analytics, 'application_withdraw', { applicationId });
    return this.withdrawJobFunc({ applicationId });
  }

  reject(applicationId: string, text: string) {
    logEvent(this.analytics, 'application_reject', { applicationId });
    return this.rejectJobFunc({ applicationId, text });
  }

  archive(applicationId: string) {
    logEvent(this.analytics, 'application_archive', { applicationId });
    const docRef = doc(this.firestore, `applications/${applicationId}`);
    return from(updateDoc(docRef, { archived: true } as Partial<Application>));
  }

  getById(id: string) {
    const docRef = doc(this.firestore, `applications/${id}`);
    return from(getDoc(docRef)).pipe(map(d => d.data() as Application));
  }

  getByJobId(jobId: string, schoolId: string) {
    const colRef = query(
      collection(this.firestore, 'applications'),
      where('job.schoolId', '==', schoolId),
      where('job.id', '==', jobId)
    );

    return collectionData(colRef, { idField: 'id' }) as Observable<
      Application[]
    >;
  }

  hasAlreadyApplied(jobId: string) {
    return this.profileService.getMe(true).pipe(
      map(me => me.id!),
      mergeMap(userId => {
        const colRef = query(
          collection(this.firestore, 'applications'),
          where('job.id', '==', jobId),
          where('userId', '==', userId)
        );
        return collectionData(colRef).pipe(map(data => data.length > 0));
      })
    );
  }

  getMy(archived = false) {
    return this.profileService.getMe(true).pipe(
      map(me => me.id!),
      mergeMap(userId => {
        const colRef = query(
          collection(this.firestore, 'applications'),
          where('userId', '==', userId),
          where('archived', '==', archived)
        );
        return collectionData(colRef, { idField: 'id' }) as Observable<
          Application[]
        >;
      })
    );
  }
}
