import { Component, Input, OnInit } from '@angular/core';
import { getPublicFileUrl } from '../../../../core/helpers';
import { School, SchoolLocation } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';

@Component({
  selector: 'app-school-header',
  templateUrl: './school-header.component.html',
  styleUrls: ['./school-header.component.scss'],
})
export class SchoolHeaderComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;

  hideAvatar = false;
  locations: SchoolLocation[] = [];

  constructor(private schoolService: SchoolService) {}

  ngOnInit(): void {
    if (this.school.locations && this.school.locations?.length > 0) {
      // add self to list
      this.locations = [
        {
          id: this.school.id,
          name: this.school.name,
          isRoot: this.school.isRoot,
          isPublic: this.school.isPublic,
          order: this.school.order,
        },
        ...this.school.locations,
      ];

      if (!this.isOwner) {
        this.locations = this.locations.filter(l => !!l.isPublic);
      }

      this.locations = this.locations.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        if (a.isRoot && !b.isRoot) {
          return -1;
        }
        if (!a.isRoot && b.isRoot) {
          return 1;
        }
        return b.name!.localeCompare(a.name!);
      });
    }

    this.hideAvatar = !this.school.avatar && !this.isOwner;
  }

  getOrder(locations: SchoolLocation[]): number | undefined {
    const i = locations.findIndex(l => !l.order && !l.isRoot);
    if (i >= 0) {
      return locations[i].order || 0 + 1;
    }

    return 0;
  }

  getAvatarUrl(school: School) {
    if (school.avatar && school.id) {
      return getPublicFileUrl('schools', school.id, 'avatar');
    }

    return 'assets/defaults/school_avatar.svg';
  }

  getBackgroundUrl(school: School) {
    if (school.backgroundImage && school.id) {
      return getPublicFileUrl('schools', school.id, 'backgroundImage');
    }

    return 'assets/defaults/background_school.jpeg';
  }

  getSchoolLevelsTitle(school: School) {
    return this.schoolService.getLevelsTitle(
      school.levels || [],
      school.levelDescriptions || []
    );
  }
}
