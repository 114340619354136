import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-round-image',
  templateUrl: './round-image.component.html',
  styleUrls: ['./round-image.component.scss'],
})
export class RoundImageComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() description?: string;
  @Input() src?: string | null;
  @Input() size: '60' | '80' | '120' = '80';
  @Input() disabled = false;
  @Input() rounded = true;

  defaultSrc = 'assets/defaults/avatar.svg';
}
