import { Component, Input } from '@angular/core';
import { Job } from '../../../../types/job';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-job-about-card',
  templateUrl: './job-about-card.component.html',
  styleUrls: ['./job-about-card.component.scss'],
})
export class JobAboutCardComponent {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.job.about_html);
  }
}
