import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-highlight-item-list',
  templateUrl: './highlight-item-list.component.html',
  styleUrl: './highlight-item-list.component.scss',
})
export class HighlightItemListComponent {
  @Input({ transform: (value: string[] | undefined) => (value ? value : []) })
  highlights: string[] = [];
}
