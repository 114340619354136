import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StreamChatModule } from 'stream-chat-angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';

import { RoundImageComponent } from '../../app/components/round-image/round-image.component';
import { FileUploadFieldComponent } from '../../app/components/form-controls/file-upload-field/file-upload-field.component';
import { ChatAvatarComponent } from '../chat/components/chat-avatar/chat-avatar.component';
import { ChatIconComponent } from '../chat/components/chat-icon/chat-icon.component';
import { ChatModalComponent } from '../chat/components/chat-modal/chat-modal.component';

@NgModule({
  declarations: [
    RoundImageComponent,
    FileUploadFieldComponent,
    ChatAvatarComponent,
    ChatIconComponent,
    ChatModalComponent,
  ],
  imports: [
    CommonModule,
    StreamChatModule,
    MatFormFieldModule,
    MatIconModule,
    MatIconButton,
    MatInputModule,
    RouterLink,
  ],
  exports: [
    RoundImageComponent,
    FileUploadFieldComponent,
    ChatAvatarComponent,
    ChatIconComponent,
    ChatModalComponent,
  ],
})
export class SharedModule {}
