import { Component, Input } from '@angular/core';
import { Job } from '../../../../types/job';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-job-tasks-card',
  templateUrl: './job-tasks-card.component.html',
  styleUrls: ['./job-tasks-card.component.scss'],
})
export class JobTasksCardComponent {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.job.yourTasks_html);
  }
}
