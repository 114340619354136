import { Component, Input } from '@angular/core';
import { Job } from '../../../../types/job';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-job-skills-card',
  templateUrl: './job-skills-card.component.html',
  styleUrls: ['./job-skills-card.component.scss'],
})
export class JobSkillsCardComponent {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.job.yourSkills_html);
  }
}
