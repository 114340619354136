@if (applications$ | async; as applications) {
  <div class="flex flex-col gap-32">
    <div>
      <h1 class="font-bold text-2xl pb-4">
        {{ jobFunction }}
      </h1>
      <div class="flex flex-col gap-4">
        @if (getByStatus(applications, 'PENDING').length === 0) {
          <strong> Keine offenen Bewerbungen vorhanden </strong>
        }
        @for (
          application of getByStatus(applications, 'PENDING');
          track application.id
        ) {
          <app-application-row
            [application]="application"
            (reject)="reject(application)"
            [loading]="isLoading(application)"></app-application-row>
        }
      </div>
    </div>
    @if (getByStatus(applications, 'WITHDRAWN').length > 0) {
      <div>
        <h1 class="font-bold text-2xl pb-4">Zurückgezogene Bewerbungen</h1>
        <div class="flex flex-col gap-4">
          @for (
            application of getByStatus(applications, 'WITHDRAWN');
            track application.id
          ) {
            <app-application-row
              [disabled]="true"
              [application]="application"></app-application-row>
          }
        </div>
      </div>
    }
    @if (getByStatus(applications, 'REJECTED').length > 0) {
      <div>
        <h1 class="font-bold text-2xl pb-4">Abgesagte Bewerbungen</h1>
        <div class="flex flex-col gap-4">
          @for (
            application of getByStatus(applications, 'REJECTED');
            track application.id
          ) {
            <app-application-row
              [disabled]="true"
              [application]="application"></app-application-row>
          }
        </div>
      </div>
    }
  </div>
}
