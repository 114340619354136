@if (allJobs) {
  <div>
    @if (!showArchived) {
      <div class="flex justify-between items-center">
        <h1 class="w-full font-bold text-2xl md:text-4xl">Meine Inserate</h1>
        <div class="flex md:hidden gap-4 mb-4">
          <button
            class="h-10 w-10 rounded-full flex justify-center items-center bg-[#ECF4FF]"
            routerLink="create">
            <mat-icon class="material-symbols-rounded"> add </mat-icon>
          </button>
          <button
            class="h-10 w-10 rounded-full border-[1px] flex justify-center items-center"
            routerLink="archived">
            <mat-icon class="material-symbols-rounded"> archive </mat-icon>
          </button>
        </div>
        <div class="hidden md:flex gap-4 mb-4">
          <a
            routerLink="create"
            class="flex gap-2 border-none action-button"
            mat-stroked-button>
            <mat-icon class="material-symbols-rounded"> add </mat-icon>
            <span class="inline-block pr-2">Erstellen</span>
          </a>
          <button
            routerLink="archived"
            class="flex gap-2"
            color="primary"
            mat-stroked-button>
            <mat-icon class="material-symbols-rounded"> archive </mat-icon>
            <span class="inline-block pr-2">Archiv</span>
          </button>
        </div>
      </div>

      <h2 class="font-bold text-xl md:text-2xl">Öffentliche Inserate</h2>
      <div class="grid grid-cols-12 gap-4">
        @if (filterPublicJobs()?.length) {
          @for (job of filterPublicJobs(); track job.id) {
            <app-hr-job-card
              class="col-span-12 md:col-span-6 xl:col-span-4 3xl:col-span-3"
              [job]="job"></app-hr-job-card>
          }
        } @else {
          <app-empty-box
            class="col-span-12"
            title="Nichts zu sehen?"
            description="Sobald du deine ersten Stelleninserate veröffentlicht hast, erscheinen sie hier."></app-empty-box>
        }
      </div>

      <div class="pt-10">
        <h2 class="font-bold text-xl md:text-2xl">Entwürfe</h2>
        <div class="grid grid-cols-12 gap-4">
          @if (filterDraftJobs()?.length) {
            @for (job of filterDraftJobs(); track job.id) {
              <app-hr-job-card
                class="col-span-12 md:col-span-6 xl:col-span-4 3xl:col-span-3"
                [job]="job"></app-hr-job-card>
            }
          } @else {
            <app-empty-box
              class="col-span-12"
              title="Nichts zu sehen?"
              description="Die Entwürfe deiner Stelleninserate werden hier angezeigt."></app-empty-box>
          }
        </div>
      </div>
    } @else {
      <h1 class="w-full font-bold text-2xl md:text-4xl">Archiv</h1>

      <div class="flex gap-2 items-center">
        <mat-form-field appearance="fill">
          <mat-label>Sortieren nach</mat-label>
          <mat-select [formControl]="archiveFilterControl">
            <mat-option value="startDate"> Antrittsdatum </mat-option>
            <mat-option value="jobName"> Berufsbezeichnung </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="mb-6"
          mat-icon-button
          color="primary"
          (click)="switchArchiveSortOrder()">
          <mat-icon class="material-symbols-rounded font-bold">
            {{
              archivedSortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward'
            }}
          </mat-icon>
        </button>
      </div>

      <div class="grid grid-cols-12 gap-4">
        @if (filterAndSortArchivedJobs()?.length) {
          @for (job of filterAndSortArchivedJobs(); track job.id) {
            <app-hr-job-card
              class="col-span-12 md:col-span-6 xl:col-span-4 3xl:col-span-3"
              [job]="job"></app-hr-job-card>
          }
        } @else {
          <app-empty-box
            class="col-span-12"
            title="Nichts zu sehen?"
            description="Hier werden deine archivierten Stelleninserate angezeigt."></app-empty-box>
        }
      </div>
    }
  </div>
}
