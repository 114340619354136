@if (!isChat) {
  <div>
    <div class="app-container" [class.is-mobile]="mobileQuery.matches">
      <mat-sidenav-container
        class="sidenav-container"
        [style.marginTop.px]="showMobileToolbar() ? 56 : 0">
        @if (profile?.isComplete) {
          <mat-sidenav
            [opened]="this.isNavbarOpen || !mobileQuery.matches"
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches"
            fixedTopGap="56"
            class="navbar w-screen md:w-[88px] 2xl:w-[22.5rem]">
            <app-navbar
              [isSchoolAdmin]="isSchoolAdmin"
              (onNavigate)="toggleNavbar()"
              (onLogout)="logout()"
              [profile]="profile"
              [logoutInProgress]="logoutInProgress" />
          </mat-sidenav>
        }

        <mat-sidenav-content
          id="scroll-area"
          class="bg-white"
          [class.isChat]="isChat">
          @if (!profile) {
            <div class="flex grow justify-center">
              <app-public-toolbar class="w-full" />
            </div>
          }
          <div class="flex justify-center">
            <div
              class="max-w-[1724px] wrapper w-full flex flex-col justify-between">
              <div class="flex flex-col gap-4 p-4 md:p-0">
                <div class="px-0 md:px-16 2xl:px-20">
                  <app-breadcrumbs />
                  <div class="py-0 md:py-16 2xl:py-20">
                    <router-outlet (activate)="onActivate($event)" />
                  </div>
                </div>
              </div>
              <div class="flex flex-col p-4 md:p-0">
                <app-footer class="px-0 md:px-16 2xl:px-20" />
              </div>
            </div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
      @if (showMobileToolbar()) {
        <mat-toolbar class="toolbar border-b-2 bg-white border-none">
          <button mat-icon-button (click)="toggleNavbar()">
            <mat-icon class="material-symbols-rounded">menu</mat-icon>
          </button>
        </mat-toolbar>
      }
    </div>
  </div>
} @else {
  <mat-sidenav-container class="chat">
    @if (profile?.isComplete) {
      <mat-sidenav
        [opened]="this.isNavbarOpen || !mobileQuery.matches"
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="56"
        class="chat__navbar">
        <app-navbar
          [isSchoolAdmin]="isSchoolAdmin"
          (onNavigate)="toggleNavbar()"
          (onLogout)="logout()"
          [profile]="profile"
          [logoutInProgress]="logoutInProgress" />
      </mat-sidenav>
    }

    <mat-sidenav-content class="chat__wrapper">
      @if (!profile) {
        <div class="flex grow justify-center">
          <app-public-toolbar class="w-full" />
        </div>
      }
      <section class="chat__wrapper__content">
        <router-outlet />
      </section>
      <footer class="chat__wrapper__footer">
        <app-footer class="px-0 md:px-16 2xl:px-20" />
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
