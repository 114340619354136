<div class="md:flex flex-wrap justify-between">
  <div>
    @if (!showArchived) {
      <h1 class="font-bold text-[2.125rem]">Meine Bewerbungen</h1>
    }
    @if (showArchived) {
      <h1 class="font-bold text-[2.125rem]">Archiv</h1>
    }
  </div>
  @if (!showArchived) {
    <button
      mat-stroked-button
      routerLink="archived"
      class="flex gap-2 action-button"
      color="primary">
      <mat-icon class="material-symbols-rounded ml-0 mr-0"> archive </mat-icon>
      <span class="inline-block">Archiv</span>
    </button>
  }
</div>
<div class="pt-8">
  <div class="grid grid-cols-12 gap-4 pt-4">
    @for (application of applications$ | async; track application.id) {
      <app-application-card
        class="col-span-12 md:col-span-6 xl:col-span-4 3xl:col-span-3"
        [application]="application"
        [routerLink]="['/applications', application.id]" />
    }

    @if ((applications$ | async)?.length === 0) {
      <app-empty-box
        class="col-span-full"
        title="Nichts zu sehen?"
        [description]="
          showArchived ? noApplicationsDescArchived : noApplicationsDesc
        "></app-empty-box>
    }
  </div>
</div>
