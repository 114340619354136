@if (!showEmptyCard) {
  <div class="grid grid-cols-12 md:gap-4">
    <div class="flex flex-col col-span-12 lg:col-span-8 lg:col-start-3">
      <div>
        <strong class="font-bold text-4xl md:text-5xl"
          >Das bringst du mit</strong
        >
        @if (isEditable) {
          <app-edit-button
            class="pl-4"
            routerLink="edit/skills"></app-edit-button>
        }
      </div>
      <app-rich-text-view
        class="pt-4"
        [html]="job.yourSkills_html"
        tailwindCssLineClamp="line-clamp-6"></app-rich-text-view>
    </div>
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Das bringst du mit"
    subtitle="Was erwartest du von deiner neuen Fachkraft?"
    description='Fachkenntnisse, Berufserfahrung und Qualifikationen. Hier schreibst du bspw., welche Anforderungen ein "must have" und welche "nice to have" sind.'
    link="edit/skills"></app-empty-card>
}
