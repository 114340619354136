<div class="grid grid-cols-12 md:gap-4">
  <div class="col-span-12 relative">
    <img
      alt="Profile Background"
      class="h-full w-full rounded-[0.75rem] md:rounded-[1.35rem] object-center object-cover"
      [src]="getBackgroundUrl(profile)" />
    @if (isOwner) {
      <app-edit-button
        [routerLink]="['/me', profile.id, 'edit', 'background']"
        class="absolute bottom-1 right-1 md:bottom-3 md:right-4"></app-edit-button>
    }
  </div>
  <div class="flex flex-col col-span-12 md:col-start-3 md:col-span-9 relative">
    <div class="flex justify-center md:block md:justify-normal">
      <div class="absolute -top-16 md:-top-24">
        <img
          alt="Profile Avatar"
          class="w-[110px] md:w-[240px] h-auto rounded-full border-white border-2"
          [src]="getAvatarUrl(profile)" />
        @if (isOwner) {
          <app-edit-button
            [routerLink]="['/me', profile.id, 'edit', 'avatar']"
            class="absolute -bottom-2.5 -right-2.5 md:bottom-3 md:right-3"></app-edit-button>
        }
      </div>
    </div>
    <div class="flex flex-col items-start md:pl-[270px] pt-16 md:pt-3">
      <div class="md:pl-3 pr-3 flex w-full">
        <div class="flex w-full justify-normal">
          <strong
            class="text-[2.125rem] leading-[3.5rem] line-clamp-2 text-ellipsis font-bold">
            {{ profile.name }}
          </strong>
        </div>
      </div>
      <div class="flex relative w-full">
        <div class="flex flex-col gap-2 xl:gap-2 sm::gap-3 2xl:gap-3">
          @if (profile.city) {
            <div class="flex md:pl-2.5 relative">
              <div>
                <mat-icon class="material-symbols-rounded scale-[1.1]">
                  location_on
                </mat-icon>
              </div>
              <span
                class="text-base sm:text-lg leading-6 pl-[7px] mt-0.5 line-clamp-1 text-ellipsis">
                {{ profile.city }}
              </span>
            </div>
          }

          <div class="flex md:pl-2.5 relative">
            <div>
              <mat-icon class="material-symbols-rounded scale-[1.1]">
                work
              </mat-icon>
            </div>
            <span class="text-base sm:text-lg leading-6 pl-[7px] mt-0.5">
              {{ getJobTitles(profile.jobFunctions) }}
            </span>
          </div>

          <div class="flex pt-1 relative gap-4 flex-wrap">
            @if (showContactButton()) {
              <div>
                <button
                  mat-mini-fab
                  color="primary"
                  class="sm:hidden shadow-none"
                  (click)="openChat()">
                  <mat-icon class="material-symbols-rounded">forum</mat-icon>
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  class="hidden sm:flex"
                  (click)="openChat()">
                  <mat-icon class="material-symbols-rounded">forum</mat-icon>
                  Kontaktieren
                </button>
              </div>
            }

            @if (profile.interestedInFulltimeJobs) {
              <button
                disableRipple
                class="bg-[#001A41] text-[#ECF4FF] cursor-default"
                mat-flat-button>
                Offen für Feststellen
              </button>
            }
            @if (profile.interestedInSubstituteJobs) {
              <button
                disableRipple
                class="bg-[#3B0716] text-[#F5DFE1] cursor-default"
                mat-flat-button>
                Offen für Stellvertretungen
              </button>
            }
          </div>
        </div>
        @if (isOwner) {
          <app-edit-button
            class="absolute right-0 sm:-right-14 -top-2"
            [background]="false"
            [routerLink]="['/me', profile.id, 'edit', 'job']"></app-edit-button>
        }
      </div>
    </div>
  </div>
</div>
