import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-school-my-item',
  standalone: false,
  templateUrl: './school-my-item.component.html',
  styleUrl: './school-my-item.component.scss',
})
export class SchoolMyItemComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() icon = '';
}
