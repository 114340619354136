<div class="flex flex-col text-base">
  <div class="flex">
    <mat-form-field class="grow" appearance="fill">
      <mat-label>Merkmale & Highlights</mat-label>
      <input
        matInput
        [formControl]="highlightControl"
        (keyup.enter)="add(highlightControl.value, true)" />
    </mat-form-field>
    <div class="pl-4 pt-2.5">
      <button
        [disabled]="!highlightControl.value"
        mat-flat-button
        color="primary"
        (click)="add(highlightControl.value, true)">
        Hinzufügen
      </button>
    </div>
  </div>
  <div class="flex flex-col gap-y-4">
    <strong>Lass dich von uns inspirieren:</strong>
    <div class="flex flex-wrap gap-2">
      @for (suggestion of suggestions; track $index; let last = $last) {
        <button class="flex text-bn-primary-blue" (click)="add(suggestion)">
          {{ suggestion }}
        </button>
        @if (!last) {
          <span>|</span>
        }
      }
    </div>
    <div class="flex flex-col gap-y-4">
      <strong>Deine Auswahl:</strong>
      <div class="flex flex-wrap gap-4">
        @for (highlight of selectedHighlights; track $index) {
          <mat-chip class="bg-bn-chip-highlight text-sm">
            <span class="mt-1 align-sub">{{ highlight }}</span>
            <button matChipRemove (click)="remove(highlight)">
              <mat-icon class="material-symbols-rounded">close</mat-icon>
            </button>
          </mat-chip>
        }
      </div>
    </div>
  </div>
</div>
