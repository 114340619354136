import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { JobService } from '../../../services/job.service';
import { PermissionsService } from '../../../services/permissions.service';
import { Job } from '../../../types/job';
import { ApplicationService } from '../../../services/application.service';
import { MatDialog } from '@angular/material/dialog';
import { JobCloseDialogComponent } from '../job-close-dialog/job-close-dialog.component';
import { NotificationService } from '../../../services/notification.service';
import { ProfileService } from '../../../services/profile.service';
import { PublicToolbarService } from '../../../services/public-toolbar.service';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent {
  id?: string | null;
  job$: Observable<Job> = EMPTY;
  fetch$ = new ReplaySubject(1);
  isAuthorized$: Observable<boolean> = EMPTY;

  loadingClose = false;
  loadingDiscard = false;
  loadingPublish = false;
  hasAlreadyApplied = false;
  isOwner = false;
  isPreview = false;

  constructor(
    route: ActivatedRoute,
    private permissionsService: PermissionsService,
    public jobService: JobService,
    private applicationService: ApplicationService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    profileService: ProfileService,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({ showBoth: true });
    this.isAuthorized$ = profileService.isAuthorized();

    route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.applicationService.hasAlreadyApplied(this.id!).subscribe(applied => {
        this.hasAlreadyApplied = applied;
      });

      this.job$ = this.fetch$.pipe(
        switchMap(() =>
          jobService.getById(this.id!).pipe(
            tap(job => {
              this.isOwner = this.permissionsService.isOwner(job.schoolRootId);
            })
          )
        )
      );
      this.reload();
    });

    this.isPreview = route.snapshot.queryParamMap.get('preview') === 'true';
  }

  isEditable(job: Job) {
    return this.isOwner && !job.isClosed && !this.isPreview;
  }

  get isLoading() {
    return this.loadingClose || this.loadingDiscard || this.loadingPublish;
  }

  reload() {
    this.fetch$.next(1);
  }

  showDiscardButton(job: Job) {
    return !job.isPublic && this.isEditable(job);
  }

  showArchiveButton(job: Job) {
    return job.isPublic && this.isEditable(job);
  }

  showApplyButton(job: Job) {
    return (
      (!this.isOwner || this.isPreview) &&
      !job.isClosed &&
      job.isPublic &&
      !this.hasAlreadyApplied
    );
  }

  shouldRenderChild(childHasData: boolean, job: Job) {
    return childHasData || this.isEditable(job);
  }

  publish() {
    this.loadingPublish = true;
    this.jobService.publish(this.id!).subscribe(() => {
      this.reload();
      this.notificationService.success('Inserat veröffentlicht.');
      this.loadingPublish = false;
    });
  }

  discard(job: Job) {
    this.loadingDiscard = true;
    this.jobService.discard(this.id!).subscribe(_ => {
      this.loadingDiscard = false;
      this.notificationService.success('Inserat verworfen.');
      this.router.navigate(['/schools', job.schoolRootId, 'jobs']);
    });
  }

  close() {
    this.loadingClose = true;
    const dialogRef = this.dialog.open(JobCloseDialogComponent, {
      disableClose: true,
      width: '100vw',
      minWidth: '100vw',
      height: '100vh',
      minHeight: '100vh',
    });
    dialogRef
      .afterClosed()
      .subscribe((result: { confirm: boolean; reason?: string }) => {
        if (result.confirm) {
          this.jobService.close(this.id!, result.reason).subscribe(() => {
            this.reload();
            this.loadingClose = false;
            this.notificationService.success('Inserat archiviert.');
          });
        } else {
          this.loadingClose = false;
        }
      });
  }
}
