import { Injectable } from '@angular/core';
import { Auth, type User } from '@angular/fire/auth';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable } from 'rxjs';

import type {
  StreamChannelAddModeratorsPayload,
  StreamChannelDemoteModeratorsPayload,
  StreamCreateUserPayload,
  StreamCreateUserTokenPayload,
  StreamRevokeUserTokenPayload,
  StreamUpdateUserPayload,
  StreamUser,
} from '../../../app/types/chat';
import { UpdateChannelAPIResponse } from 'stream-chat';

@Injectable({
  providedIn: 'root',
})
export class StreamUserService {
  private readonly streamCreateUserFunc: (
    data: StreamCreateUserPayload
  ) => Observable<StreamUser>;
  private readonly streamCreateUserTokenFunc: (
    data: StreamCreateUserTokenPayload
  ) => Observable<string>;
  private readonly streamRevokeUserTokenFunc: (
    data: StreamRevokeUserTokenPayload
  ) => Observable<StreamUser>;
  private readonly streamUpdateUserFunc: (
    data: StreamUpdateUserPayload
  ) => Observable<StreamUser>;
  private readonly channelAddModeratorsFunc: (
    data: StreamChannelAddModeratorsPayload
  ) => Observable<UpdateChannelAPIResponse | null>;
  private readonly channelDemoteModeratorsFunc: (
    data: StreamChannelDemoteModeratorsPayload
  ) => Observable<UpdateChannelAPIResponse | null>;

  constructor(
    private auth: Auth,
    functions: Functions
  ) {
    this.streamCreateUserFunc = httpsCallableData<
      StreamCreateUserPayload,
      StreamUser
    >(functions, 'streamCreateUser', {});
    this.streamCreateUserTokenFunc = httpsCallableData<
      StreamCreateUserTokenPayload,
      string
    >(functions, 'streamCreateUserToken', {});
    this.streamRevokeUserTokenFunc = httpsCallableData<
      StreamRevokeUserTokenPayload,
      StreamUser
    >(functions, 'streamRevokeUserToken', {});
    this.streamUpdateUserFunc = httpsCallableData<
      StreamUpdateUserPayload,
      StreamUser
    >(functions, 'streamUpdateUser', {});
    this.channelAddModeratorsFunc = httpsCallableData<
      StreamChannelAddModeratorsPayload,
      UpdateChannelAPIResponse | null
    >(functions, 'channelAddModerators', {});
    this.channelDemoteModeratorsFunc = httpsCallableData<
      StreamChannelDemoteModeratorsPayload,
      UpdateChannelAPIResponse | null
    >(functions, 'channelDemoteModerators', {});
  }

  public currentUser(): User {
    return this.auth.currentUser!;
  }

  public getToken(uid?: string): Observable<string> {
    return this.streamCreateUserTokenFunc({
      uid: uid ?? this.currentUser().uid,
    });
  }

  public createUser(
    user: Omit<StreamCreateUserPayload['user'], 'id'>
  ): Observable<StreamUser> {
    return this.streamCreateUserFunc({
      user: { ...user, id: this.currentUser().uid },
    });
  }

  public logout(): Observable<StreamUser> {
    return this.streamRevokeUserTokenFunc({ uid: this.currentUser().uid });
  }

  public updateUser(
    user: Omit<StreamUpdateUserPayload['user'], 'id'>
  ): Observable<StreamUser> {
    return this.streamUpdateUserFunc({ user, uid: this.currentUser().uid });
  }

  public addModerators(
    cid: StreamChannelAddModeratorsPayload['cid'],
    members: StreamChannelAddModeratorsPayload['members']
  ): Observable<UpdateChannelAPIResponse | null> {
    return this.channelAddModeratorsFunc({ cid, members });
  }

  public demoteModerators(
    cid: StreamChannelDemoteModeratorsPayload['cid'],
    members: StreamChannelDemoteModeratorsPayload['members']
  ): Observable<UpdateChannelAPIResponse | null> {
    return this.channelDemoteModeratorsFunc({ cid, members });
  }
}
