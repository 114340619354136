<div class="flex flex-col w-full">
  <h1>Admins verwalten</h1>
  <p>
    Lade hier weitere Bildigsnetz-Mitglieder ein, welche deine Schule verwalten
    dürfen. Diese werden direkt per E-Mail benachrichtigt. Die entsprechende
    Schule finden sie dann in ihrem persönlichen Profil unter "Meine Schule".
  </p>

  <mat-form-field appearance="fill">
    <mat-label>Person einladen</mat-label>
    <input
      type="text"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (profile of profiles$ | async; track profile.id) {
        <mat-option [value]="profile" (click)="addPermission(profile)">
          {{ profile.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <!-- requests -->
  @if (permissionRequestsCount > 0) {
    <div
      class="flex flex-col p-8 border-2 border-solid border-color rounded-2xl mt-4">
      <h2>Anfragen</h2>
      @for (request of permissionRequests$ | async; track request.id) {
        <div>
          <div class="flex justify-between content-center h-16 mt-2 mb-2">
            <div>
              <app-round-image
                [routerLink]="['/profile', request.userId]"
                size="60"
                [src]="getImageUrl(request.userId)"
                [title]="request.username"
                [subtitle]="request.email"></app-round-image>
            </div>
            <div>
              <button mat-button (click)="decline(request)">ablehnen</button>
              <button mat-button (click)="approve(request)">annehmen</button>
            </div>
          </div>
        </div>
      }
    </div>
  }

  <!-- permissions -->
  <div class="flex flex-col pt-24 gap-16">
    @for (
      permission of permissionControls | reverse;
      track permission;
      let last = $last
    ) {
      <div>
        <div class="flex flex-col h-fit mt-2 mb-2">
          <div class="flex w-full justify-between">
            <div class="self-center">
              <app-round-image
                class="max-w-xs"
                [routerLink]="['/profile', permission.userId]"
                size="60"
                [src]="getImageUrl(permission.userId)"
                [title]="permission.username"></app-round-image>
            </div>
            <button
              class="self-center"
              mat-icon-button
              (click)="revokePermission(permission, permission.permissionId)">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </div>
        </div>
        <div>
          <div class="pt-8 pb-2 text-lg">
            <strong class="font-bold">Ansprechperson von:</strong>
          </div>
          <mat-selection-list [formControl]="permission.schoolIds">
            @for (
              location of schoolLocations | sortby: 'order';
              track location.id
            ) {
              <mat-list-option [value]="location.id" color="primary">
                {{ location.name }}
              </mat-list-option>
            }
          </mat-selection-list>
        </div>
        <div>
          <div class="pt-4 pb-2 text-lg">
            <strong class="font-bold">öffentliche Kontaktdaten</strong>
          </div>
          <div class="flex flex-col md:flex-row md:gap-4">
            <mat-form-field class="grow">
              <mat-label>E-Mail Adresse</mat-label>
              <input matInput [formControl]="permission.publicEmail" />
            </mat-form-field>
            <mat-form-field class="grow">
              <mat-label>Telefonnummer</mat-label>
              <input matInput [formControl]="permission.publicPhone" />
            </mat-form-field>
            <mat-form-field class="grow">
              <mat-label>Berufsbezeichnung</mat-label>
              <input matInput [formControl]="permission.publicJobFunction" />
            </mat-form-field>
          </div>
        </div>
        @if (!last) {
          <mat-divider></mat-divider>
        }
      </div>
    }

    <div class="pt-8 flex justify-end gap-4">
      <a mat-button backButton>Zurück</a>
      <app-action-button
        (onClick)="save()"
        [loading]="loading"
        text="Speichern" />
    </div>
  </div>
</div>

@if (showLoadingSpinner) {
  <div
    class="flex justify-center items-center absolute bg-black/10 top-0 left-0 w-full h-full">
    <div class="flex flex-col justify-center items-center gap-8">
      <mat-spinner color="primary"></mat-spinner>
    </div>
  </div>
}
