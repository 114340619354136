import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { FormBuilder } from '@angular/forms';
import { ProfileService } from '../../../services/profile.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-marketing',
  templateUrl: './settings-marketing.component.html',
  styleUrls: ['./settings-marketing.component.scss'],
})
export class SettingsMarketingComponent {
  form = this.fb.group({
    newsletterEmailOptIn: [false, []],
    chatNotificationEmailOptIn: [true, []],
  });

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private router: Router,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Benachrichtigungen' },
    ]);

    this.profileService.getPreferences().subscribe(settings => {
      this.form.setValue({
        newsletterEmailOptIn: settings.newsletterEmailOptIn,
        chatNotificationEmailOptIn: settings.chatNotificationEmailOptIn ?? true,
      });
    });
  }

  save() {
    const { newsletterEmailOptIn, chatNotificationEmailOptIn } =
      this.form.value;
    this.profileService
      .updatePreferences({
        newsletterEmailOptIn: newsletterEmailOptIn!,
        chatNotificationEmailOptIn: chatNotificationEmailOptIn!,
      })
      .subscribe(_ => {
        this.notificationService.success('E-Mail-Einstellungen aktualisiert');
        this.router.navigate(['/settings']);
      });
  }
}
