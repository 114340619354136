import { Component, Input } from '@angular/core';
import { School } from '../../../../types/school';

@Component({
  selector: 'app-schools-my-progress-card',
  standalone: false,
  templateUrl: './schools-my-progress-card.component.html',
  styleUrl: './schools-my-progress-card.component.scss',
})
export class SchoolsMyProgressCardComponent {
  @Input() schoolGroup: School[] = [];

  get locations() {
    return this.schoolGroup.filter(school => !school.isRoot);
  }

  get root() {
    return this.schoolGroup.find(school => school.isRoot);
  }

  get progess() {
    const total = this.schoolGroup.length;
    const done = this.schoolGroup.filter(school => school.isPublic).length;

    return Math.ceil((done / total) * 100);
  }
}
