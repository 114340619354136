import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, firstValueFrom } from 'rxjs';
import { JobService } from '../../../services/job.service';
import { Job } from '../../../types/job';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BucketFile, MediaFile } from '../../../types/core';
import { FileService } from '../../../services/files.service';
import { ProfileService } from '../../../services/profile.service';
import { ApplicationService } from '../../../services/application.service';
import { ApplicationRequest } from '../../../types/application';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-job-apply',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.scss'],
})
export class JobApplyComponent implements OnInit {
  loading = false;
  id?: string;
  job$: Observable<Job> = EMPTY;

  cvControl = new FormControl<MediaFile | null>(null, [Validators.required]);
  qualificationControls: FormControl<MediaFile | null>[] = [];
  additionalDocControls: FormControl<MediaFile | null>[] = [];

  form = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    phone: ['', [Validators.required]],
    message: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private fileService: FileService,
    private profileService: ProfileService,
    private applicationService: ApplicationService,
    private dialog: MatDialog,
    jobService: JobService,
    route: ActivatedRoute
  ) {
    route.paramMap.subscribe(params => {
      this.id = params.get('id') as string;
      this.job$ = jobService.getById(this.id!);
    });
  }

  ngOnInit(): void {
    this.profileService.getMe(true).subscribe(profile => {
      this.profileService.getContact().subscribe(contact => {
        this.profileService.getAttachements().subscribe(attachements => {
          // Contact Form
          this.form.setValue({
            name: profile.name || '',
            email: contact.email || '',
            message: '',
            phone: contact.phone || '',
          });

          // CV
          if (attachements?.cv) {
            this.cvControl.setValue(
              this.getMediaFileFromBucketFile(attachements.cv)
            );
          }

          // Diplomas & Certificates
          this.qualificationControls =
            attachements?.qualifications?.map(
              f =>
                new FormControl<MediaFile | null>(
                  this.getMediaFileFromBucketFile(f)
                )
            ) || [];
          this.qualificationControls.push(
            new FormControl<MediaFile | null>(null)
          );

          // Additional Docs
          this.additionalDocControls =
            attachements?.additionalDocs?.map(
              f =>
                new FormControl<MediaFile | null>(
                  this.getMediaFileFromBucketFile(f)
                )
            ) || [];
          this.additionalDocControls.push(
            new FormControl<MediaFile | null>(null)
          );
        });
      });
    });
  }

  private getMediaFileFromBucketFile(bucketFile: BucketFile) {
    return {
      displayUrl: bucketFile.url || '',
      description: bucketFile.description || '',
      filename: bucketFile.filename || '',
      type: bucketFile.mimetype || '',
      uploaded: true,
    };
  }

  isApplyButtonEnabled() {
    return this.form.valid && this.cvControl.valid;
  }

  add(type: 'qualification' | 'additionalDoc') {
    const control = new FormControl<MediaFile | null>(null);

    if (type === 'additionalDoc') {
      this.additionalDocControls.push(control);
    } else {
      this.qualificationControls.push(control);
    }
  }

  remove(index: number, type: 'qualification' | 'additionalDoc') {
    const list =
      type === 'qualification'
        ? this.qualificationControls
        : this.additionalDocControls;
    list.splice(index, 1);
  }

  async uploadFile(userId: string, file: MediaFile, name: string) {
    const path = `profiles/${userId}/application`;
    if (file.uploaded) {
      return {
        path: `${path}/${name}`,
        url: file.displayUrl || null,
        description: file.description || file.filename,
        filename: file.filename || null,
        mimetype: 'application/pdf',
      } as BucketFile;
    }

    return await this.fileService.uploadAsync(
      path,
      file.file!,
      name,
      true,
      file.description
    );
  }

  async apply() {
    this.loading = true;

    const data = this.form.value as Partial<ApplicationRequest>;
    const qualifications = this.qualificationControls
      .filter(c => !!c.value)
      .map(c => c.value as MediaFile);
    const additionalDocs = this.additionalDocControls
      .filter(c => !!c.value)
      .map(c => c.value as MediaFile);

    const profile = await firstValueFrom(this.profileService.getMe(true));
    const userId = profile.id!;

    const cv = await this.uploadFile(userId, this.cvControl.value!, 'cv.pdf');
    const uploadedQualifications = await Promise.all(
      qualifications.map((c, i) =>
        this.uploadFile(userId, c, `qualification_${i}.pdf`)
      )
    );
    const uploadedAdditionalDocs = await Promise.all(
      additionalDocs.map((d, i) =>
        this.uploadFile(userId, d, `additionalDoc_${i}.pdf`)
      )
    );

    await firstValueFrom(
      this.profileService.updateAttachements({
        cv,
        qualifications: uploadedQualifications || [],
        additionalDocs: uploadedAdditionalDocs || [],
      })
    );

    this.applicationService
      .apply({
        job: { id: this.id! },
        email: data.email!,
        name: data.name,
        title: profile.title || '',
        phone: data.phone,
        message: data.message,
        qualifications: uploadedQualifications,
        additionalDocs: uploadedAdditionalDocs,
        cv,
      })
      .subscribe(_ => {
        this.loading = false;
        this.dialog
          .open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: {
              onlyAcceptButton: true,
              buttonColor: 'primary',
              text: 'Gratuliere - Du hast dich erfolgreich beworben!',
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['/applications', 'my']);
          });
      });
  }
}
