import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, EMPTY, firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../../services/application.service';
import { FileService } from '../../../services/files.service';
import { JobService } from '../../../services/job.service';
import { ProfileService } from '../../../services/profile.service';
import { ApplicationRequest } from '../../../types/application';
import { MediaFile, BucketFile } from '../../../types/core';
import { Job } from '../../../types/job';
import { Auth, signInAnonymously } from '@angular/fire/auth';
import { PublicToolbarService } from '../../../services/public-toolbar.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-job-apply-public',
  templateUrl: './job-apply-public.component.html',
  styleUrls: ['./job-apply-public.component.scss'],
})
export class JobApplyPublicComponent implements OnInit {
  loading = false;
  id?: string;
  job$: Observable<Job> = EMPTY;
  isAuthorized$: Observable<boolean> = EMPTY;

  cvControl = new FormControl<MediaFile | null>(null, [Validators.required]);
  qualificationControls: FormControl<MediaFile | null>[] = [];
  additionalDocControls: FormControl<MediaFile | null>[] = [];

  form = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    phone: ['', [Validators.required]],
    message: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private fileService: FileService,
    private profileService: ProfileService,
    private applicationService: ApplicationService,
    private dialog: MatDialog,
    private router: Router,
    private auth: Auth,
    jobService: JobService,
    route: ActivatedRoute,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({ showBoth: true });

    this.isAuthorized$ = profileService.isAuthorized();
    route.paramMap.subscribe(params => {
      this.id = params.get('id') as string;
      this.job$ = jobService.getById(this.id!);
    });
  }

  async ngOnInit() {
    await this.auth.signOut();

    this.form.setValue({
      name: '',
      email: '',
      message: '',
      phone: '',
    });
    this.qualificationControls.push(new FormControl<MediaFile | null>(null));
    this.additionalDocControls.push(new FormControl<MediaFile | null>(null));
  }

  isApplyButtonEnabled() {
    return this.form.valid && this.cvControl.valid;
  }

  add(type: 'qualification' | 'additionalDoc') {
    const control = new FormControl<MediaFile | null>(null);

    if (type === 'additionalDoc') {
      this.additionalDocControls.push(control);
    } else {
      this.qualificationControls.push(control);
    }
  }

  remove(index: number, type: 'qualification' | 'additionalDoc') {
    const list =
      type === 'qualification'
        ? this.qualificationControls
        : this.additionalDocControls;
    list.splice(index, 1);
  }

  async uploadFile(userId: string, file: MediaFile, name: string) {
    const path = `profiles/${userId}/application`;
    if (file.uploaded) {
      return {
        path: `${path}/${name}`,
        url: file.displayUrl || null,
        description: file.description || file.filename,
        filename: file.filename || null,
        mimetype: 'application/pdf',
      } as BucketFile;
    }

    return await this.fileService.uploadAsync(
      path,
      file.file!,
      name,
      true,
      file.description
    );
  }

  async apply() {
    this.loading = true;

    const data = this.form.value as Partial<ApplicationRequest>;
    const qualifications = this.qualificationControls
      .filter(c => !!c.value)
      .map(c => c.value as MediaFile);
    const additionalDocs = this.additionalDocControls
      .filter(c => !!c.value)
      .map(c => c.value as MediaFile);

    const userCredentials = await signInAnonymously(this.auth);
    const userId = userCredentials.user.uid;

    const cv = await this.uploadFile(userId, this.cvControl.value!, 'cv.pdf');
    const uploadedQualifications = await Promise.all(
      qualifications.map((c, i) =>
        this.uploadFile(userId, c, `qualification_${i}.pdf`)
      )
    );
    const uploadedAdditionalDocs = await Promise.all(
      additionalDocs.map((d, i) =>
        this.uploadFile(userId, d, `additionalDoc_${i}.pdf`)
      )
    );

    await firstValueFrom(
      this.profileService.updateAttachements({
        cv,
        qualifications: uploadedQualifications || [],
        additionalDocs: uploadedAdditionalDocs || [],
      })
    );

    this.applicationService
      .apply({
        job: { id: this.id! },
        email: data.email!,
        name: data.name,
        title: '',
        phone: data.phone,
        message: data.message,
        qualifications: uploadedQualifications,
        additionalDocs: uploadedAdditionalDocs,
        cv,
      })
      .subscribe(async _ => {
        await this.auth.signOut();
        this.loading = false;
        this.dialog
          .open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: {
              onlyAcceptButton: true,
              buttonColor: 'primary',
              text: 'Gratuliere - Du hast dich erfolgreich beworben!',
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['/jobs', this.id]);
          });
      });
  }
}
