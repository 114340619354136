<div
  class="flex border-dot rounded-[0.75rem] md:rounded-[1.35rem] p-5 sm:p-10 xl:p-11">
  <div class="flex grow flex-col gap-5">
    <div>
      <h2 class="font-bold text-2xl lg:text-4xl">
        Veröffentlichung Schulprofil
      </h2>
    </div>
    <div class="flex flex-col lg:flex-row justify-between grow gap-x-8 gap-y-5">
      <div class="flex flex-col xl:flex-row w-1/2 gap-8">
        <div class="flex flex-row flex-wrap md:flex-nowrap gap-8">
          <div
            class="flex flex-col lg:text-base text-sm gap-2 w-full sm:w-auto">
            <strong class="font-bold">Schuleinheit</strong>
            <app-progress-card-item
              [text]="root?.name || 'Schuleinheit'"
              [link]="'/schools/' + root?.id"
              [success]="!!root?.isPublic" />
            @if (locations.length > 0) {
              <strong class="text-base font-bold">Standorte</strong>
              @for (
                location of locations | sortby: 'order';
                track location.id
              ) {
                <app-progress-card-item
                  [text]="location.name || 'Standort'"
                  [link]="'/schools/' + location.id"
                  [success]="!!location?.isPublic" />
              }
            }
          </div>
        </div>
      </div>
      <app-progress-bar
        class="flex justify-end w-full lg:w-1/2"
        [progress]="progess" />
    </div>
  </div>
</div>
