@if (flat) {
  <button
    class="flex w-full"
    [ngStyle]="{
      'background-color': !!customBgColor && !disabled ? customBgColor : ''
    }"
    mat-flat-button
    [color]="themeColor"
    [disabled]="loading || disabled"
    (click)="triggerAction()"
    [class.spinner]="loading">
    @if (!loading) {
      <span
        [ngStyle]="{
          color: !!customTextColor ? customTextColor : ''
        }"
        >{{ text }}</span
      >
    }
  </button>
}
@if (!flat) {
  <button
    class="flex w-full"
    mat-button
    [ngStyle]="{
      'background-color': !!customBgColor && !disabled ? customBgColor : ''
    }"
    [color]="themeColor"
    [disabled]="loading || disabled"
    (click)="triggerAction()"
    [class.spinner]="loading">
    @if (!loading) {
      <span
        [ngStyle]="{
          color: !!customTextColor ? customTextColor : ''
        }"
        >{{ text }}</span
      >
    }
  </button>
}
