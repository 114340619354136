import { ApiVideoAsset, BaseModel, BucketFile, GeoPoint } from './core';

export type School = BaseModel &
  SchoolAbout & {
    name?: string;
    rootId: string;
    title?: string;
    contacts?: SchoolContact[];
    address?: SchoolAddress | null;

    type?: (typeof SchoolTypes)[number];
    levels?: (typeof SchoolLevels)[number][];
    levelDescriptions?: (typeof SchoolLevelDescriptions)[number][];
    cooperatingCollegeOfEducation?: (typeof CollegesOfEducation)[number];

    whatWeOffer?: string;
    whatWeOffer_html?: string;
    whatWeOfferHighlights?: string[];

    pedagogicFocus?: string;
    pedagogicFocus_html?: string;
    pedagogicFocusHighlights?: string[];

    media?: BucketFile[];
    documents?: BucketFile[];
    video?: ApiVideoAsset | null;

    countJobs?: number;
    verified?: boolean;
    verifiedOn?: string;
    isPublic?: boolean;

    avatar?: BucketFile | null;
    backgroundImage?: BucketFile | null;

    isRoot?: boolean;
    order?: number;
    locations?: SchoolLocation[];
    _geoloc?: GeoPoint;
  };

export type SchoolLocation = {
  id?: string;
  name?: string;
  isRoot?: boolean;
  isPublic?: boolean;
  order?: number;
};

export type SchoolAbout = {
  countStudents?: string;
  countEmployees?: string;
  about?: string;
  aboutHighlights?: string[];
  about_html?: string;
};

export type SchoolContact = {
  userId: string;
  name: string;
  email: string;
  phone: string;
  jobFunction: string;
};

export type SchoolAddress = {
  street?: string;
  zipcode?: string;
  city?: string;
  canton?: string;
  municipality?: string; // Gemeinde
  phone?: string;
  website?: string;
  email?: string;
};

export type SchoolVerfication = BaseModel & {
  userId: string;
  email: string;
};

export const SchoolTypes = [
  'Öffentliche Schule',
  'Privatschule',
  'Sonderschule',
] as const;

export const SchoolLevels = [
  'Zyklus I',
  'Zyklus II',
  'Zyklus III',
  'Basisstufe',
  'Kindergarten',
  'Unterstufe',
  'Mittelstufe',
  'Sekundarstufe I',
  'Sekundarstufe II',
] as const; // Stufe

export const SchoolLevelsShortMap = {
  ['Zyklus I']: 'Zyklus I',
  ['Zyklus II']: 'Zyklus II',
  ['Zyklus III']: 'Zyklus III',
  ['Basisstufe']: 'Basisstufe',
  ['Kindergarten']: 'Kiga',
  ['Unterstufe']: 'Ust',
  ['Mittelstufe']: 'Mst',
  ['Primarstufe']: 'Primar',
  ['Sekundarstufe I']: 'Sek 1',
  ['Sekundarstufe II']: 'Sek 2',
};

export const SchoolLevelDescriptions = [
  'Gymnasium',
  'Fachmittelschule',
  'Berufsmaturitätsschule',
  'Brückenangebote',
  'Berufsschule',
] as const;

export const RequiredRootSchoolFields = [
  'type',
  'levels',
  'about',
  'contacts',
  'countStudents',
  'countEmployees',
  'address',
];

export const RequiredSchoolFields = [
  'type',
  'levels',
  'about',
  'contacts',
  'countStudents',
  'countEmployees',
  'address',
];

export const CollegesOfEducation = [
  'PHZH',
  'PHLU',
  'PHBern',
  'PHSG',
  'PHSZ',
  'PHGR',
  'FHNW',
  'PH Wallis',
  'PHFR',
  'PHTG',
  'PHZG',
  'PHSH',
] as const;

export const CollegesOfEducationInfoMap = {
  ['PHZH']: {
    name: 'PH Zürich',
    url: 'https://phzh.ch/ueber-die-phzh/berufspraxis-partnerschaften/kooperationsschulen-und-praxislehrpersonen/kooperationsschulen',
  },
  ['PHLU']: {
    name: 'PH Luzern',
    url: 'https://www.phlu.ch',
  },
  ['PHBern']: {
    name: 'PH Bern',
    url: 'https://www.phbern.ch',
  },
  ['PHSG']: {
    name: 'PH St. Gallen',
    url: 'https://www.phsg.ch',
  },
  ['PHSZ']: {
    name: 'PH Schwyz',
    url: 'https://www.phsz.ch',
  },
  ['PHGR']: {
    name: 'PH Graubünden',
    url: 'https://phgr.ch',
  },
  ['FHNW']: {
    name: 'PH der FH Nordwestschweiz',
    url: 'https://www.fhnw.ch/de/die-fhnw/hochschulen/ph',
  },
  ['PH Wallis']: {
    name: 'PH Wallis',
    url: 'https://www.hepvs.ch/de',
  },
  ['PHFR']: {
    name: 'PH Freiburg',
    url: 'https://hepfr.ch/de',
  },
  ['PHTG']: {
    name: 'PH Thurgau',
    url: 'https://www.phtg.ch',
  },
  ['PHZG']: {
    name: 'PH Zug',
    url: 'https://www.zg.ch/behoerden/direktion-fur-bildung-und-kultur/phzg',
  },
  ['PHSH']: {
    name: 'PH Schaffhausen',
    url: 'https://www.phsh.ch',
  },
};
