import { Component, Input } from '@angular/core';
import { Job } from '../../../../types/job';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-job-we-offer-card',
  templateUrl: './job-we-offer-card.component.html',
  styleUrls: ['./job-we-offer-card.component.scss'],
})
export class JobWeOfferCardComponent {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.job.weOffer_html);
  }
}
