import { Component, Input, OnInit } from '@angular/core';
import { Application } from '../../types/application';
import { Job } from 'src/app/types/job';
import { JobContextMenuItem } from '../job-card/job-card.component';
import { ApplicationService } from 'src/app/services/application.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
})
export class ApplicationCardComponent implements OnInit {
  @Input({ required: true }) application!: Application;

  job = {} as Job;
  menuEntries: JobContextMenuItem[] = [];
  isProcessing = false;

  constructor(
    private applicationService: ApplicationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.job = this.application.job as Job;
    this.buildContextMenu();
  }

  onClickContextItem(itemId: string) {
    switch (itemId) {
      case 'withdraw':
        this.handleClickWithdraw();
        break;
      case 'archive':
        this.handleClickArchive();
        break;
    }
  }

  private buildContextMenu() {
    if (this.application.status === 'PENDING') {
      this.menuEntries.push({
        id: 'withdraw',
        icon: 'undo',
        text: 'Zurückziehen',
      });
    }

    if (!this.application.archived) {
      this.menuEntries.push({
        id: 'archive',
        icon: 'archive',
        text: 'Archivieren',
      });
    }
  }

  private handleClickWithdraw() {
    this.isProcessing = true;
    this.applicationService.withdraw(this.application.id!).subscribe(_ => {
      this.isProcessing = false;
      this.notificationService.success('Bewerbung wurde zurückgezogen.');
    });
  }

  private handleClickArchive() {
    this.isProcessing = true;
    this.applicationService.archive(this.application.id!).subscribe(_ => {
      this.isProcessing = false;
      this.notificationService.success('Bewerbung wurde archiviert.');
    });
  }
}
