<app-job-card
  [job]="job"
  [menuItems]="menuEntries"
  [isProcessing]="isProcessing"
  (clickContextItem)="onClickContextItem($event)">
  @if (job.isPublic || job.isClosed) {
    <a
      class="flex items-center gap-2 pt-6 text-sm md:text-base cursor-pointer hover-bold-icon mt-auto"
      [routerLink]="[
        '/my-schools',
        job.schoolId,
        'jobs',
        job.id,
        'applicants'
      ]">
      <mat-icon class="material-symbols-rounded font-bold">mail</mat-icon>
      <span class="font-bold pt-[2px]">{{ getApplicationsText() }}</span>
    </a>
  }
</app-job-card>
