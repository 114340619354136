<app-job-card
  [hasHover]="true"
  [job]="job"
  [menuItems]="menuEntries"
  [isProcessing]="isProcessing"
  (clickContextItem)="onClickContextItem($event)">
  @if (
    application.status === 'REJECTED' || application.status === 'WITHDRAWN'
  ) {
    <div class="text-sm font-bold pt-6 mt-auto">
      <div class="flex items-center text-[#B00020] gap-2">
        <mat-icon class="material-symbols-rounded">info</mat-icon>
        <span class="pt-1">{{
          application.status === 'REJECTED' ? 'Absage' : 'Zurückgezogen'
        }}</span>
      </div>
    </div>
  }
</app-job-card>
