import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, tap } from 'rxjs';
import { PermissionsService } from '../../../services/permissions.service';
import { SchoolService } from '../../../services/school.service';
import {
  RequiredRootSchoolFields,
  RequiredSchoolFields,
  School,
} from '../../../types/school';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { ProfileService } from '../../../services/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { PublicToolbarService } from '../../../services/public-toolbar.service';

@Component({
  selector: 'app-school-profile',
  templateUrl: './school-profile.component.html',
  styleUrls: ['./school-profile.component.scss'],
})
export class SchoolProfileComponent {
  id?: string;
  school$?: Observable<School>;
  isAuthorized$: Observable<boolean> = EMPTY;
  missingFields: string[] = [];
  loading = false;
  isOwner = false;

  constructor(
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    private permissionsService: PermissionsService,
    private schoolService: SchoolService,
    private dialog: MatDialog,
    private router: Router,
    profileService: ProfileService,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({ showBoth: true });

    route.paramMap.subscribe(params => {
      this.id = params.get('id') as string;
      this.isAuthorized$ = profileService.isAuthorized();
      this.school$ = this.schoolService.getById(this.id).pipe(
        tap(school => {
          this.isOwner = this.permissionsService.isOwner(school.rootId);
        }),
        tap(school => {
          if (this.isOwner) {
            breadcrumbService.set([
              { text: 'Meine Schule', link: 'my-schools' },
              { text: school.name || '' },
            ]);
          }
          return this.updateMissingFields(school);
        })
      );
    });
  }

  async preview() {
    const navExtras = { queryParams: { preview: 'true' } };
    await this.router.navigate(['/me', this.id], navExtras);
  }

  updateMissingFields(school: School | any) {
    const requiredFields = school.isRoot
      ? RequiredRootSchoolFields
      : RequiredSchoolFields;
    this.missingFields = requiredFields.filter(field => !school[field]);
  }

  publish(school: School) {
    this.loading = true;
    this.school$ = this.schoolService.publish(school).pipe(
      tap(() => (this.loading = false)),
      tap(() =>
        this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '600px',
          data: {
            onlyAcceptButton: true,
            buttonColor: 'primary',
            text: 'Gratuliere - du hast dein Schulprofil erfolgreich veröffentlicht. Somit hast du dich wirksam in der Gesellschaft und spezifisch im Bildungsmarkt positioniert. Deine Schule kann nun unter “Schulen” gefunden werden.',
          },
        })
      )
    );
  }
}
