import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Profile } from '../../types/profile';
import { SchoolLevels, SchoolLevelsShortMap } from '../../types/school';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChatClientService } from 'stream-chat-angular';
import { from, switchMap } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() profile?: Profile;
  @Input() isSchoolAdmin = false;
  @Input() logoutInProgress: boolean = false;
  @Output() onLogout = new EventEmitter<void>();
  @Output() onNavigate = new EventEmitter<void>();

  minQuery: MediaQueryList;
  maxQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;
  unreadMessageCount: number = 0;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private chatService: ChatClientService
  ) {
    this.minQuery = media.matchMedia('(max-width: 1536px)');
    this.maxQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
    this.minQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.chatService.user$
      .pipe(
        switchMap(user =>
          from(this.chatService.chatClient.getUnreadCount(user?.id))
        )
      )
      .subscribe(({ total_unread_count }) => {
        this.unreadMessageCount = total_unread_count;
      });
  }

  logout() {
    this.onLogout.emit();
    this.onNavigate.emit();
  }

  navigate() {
    this.onNavigate.emit();
  }

  getJobLevels(levels: (typeof SchoolLevels)[number][]) {
    if (!levels) return '';
    if (
      levels.includes('Kindergarten') &&
      levels.includes('Unterstufe') &&
      levels.includes('Mittelstufe')
    ) {
      return 'Kiga - Primar';
    }
    if (levels.includes('Unterstufe') && levels.includes('Mittelstufe')) {
      return 'Primar';
    }
    if (levels.includes('Kindergarten') && levels.includes('Unterstufe')) {
      return 'KUST';
    }

    return levels
      .map(level => SchoolLevelsShortMap[level] || level)
      .join(' - ');
  }

  getJobTitle() {
    const firstJobFunction = this.profile?.jobFunctions[0];
    const inStudy = firstJobFunction?.inStudy ? ' i.A.' : '';
    return `${firstJobFunction?.name}${inStudy}`;
  }

  showTooltips() {
    return !(this.minQuery.matches && this.maxQuery.matches);
  }

  getAvatarUrl() {
    return this.profile?.avatar?.url || 'assets/defaults/avatar.svg';
  }
}
