@if (!showEmptyCard) {
  <div class="grid grid-cols-12 md:gap-4 pr-4">
    <div
      class="flex flex-col justify-between col-span-12 lg:col-span-8 lg:col-start-3">
      <div class="flex w-fit">
        <strong
          class="font-bold text-4xl md:text-5xl overflow-visible whitespace-nowrap">
          Das bieten wir
        </strong>
        @if (isEditable) {
          <app-edit-button
            class="pl-4"
            routerLink="edit/weoffer"></app-edit-button>
        }
      </div>
      <app-rich-text-view
        class="pt-4"
        [html]="job.weOffer_html"
        tailwindCssLineClamp="line-clamp-[17]"></app-rich-text-view>
    </div>
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Das bieten wir"
    subtitle="Mut zum Perspektivenwechsel."
    description="Überlege was sich Bewerbende von ihrem zukünftigen Arbeitgeber wünschen. Wie sieht ein richtig attraktives Angebot aus Bewerbersicht aus und was davon kannst du bieten?"
    link="edit/weoffer"></app-empty-card>
}
