@if (!showEmptyCard) {
  <div
    class="flex flex-col justify-between p-5 md:p-10 text-base border-2 border-bn-grey-border rounded-2xl">
    <div class="flex justify-between gap-5">
      <strong
        class="font-bold text-2xl md:text-5xl overflow-visible whitespace-nowrap">
        {{ getTitle() }}
      </strong>
      @if (isOwner) {
        <app-edit-button [routerLink]="permissionLink" />
      }
    </div>
    <div class="flex md:w-fit flex-col pt-4 gap-8">
      <div class="flex gap-y-5 gap-x-16 flex-col md:flex-row md:flex-wrap">
        @for (contact of school.contacts; track contact.userId) {
          <div
            class="flex justify-between md:justify-start md:gap-10 lg:min-w-[45%]">
            <a [routerLink]="['/profile', contact.userId]">
              <div class="flex md:flex-1 gap-3 md:gap-5">
                <div
                  class="flex grow image-container rounded-full min-h-[60px] min-w-[60px] h-[60px] w-[60px] md:h-[80px] md:w-[80px] md:min-h-[80px] md:min-w-[80px]"
                  [ngStyle]="{
                    'background-image':
                      'url(' +
                      getImageUrl(contact.userId) +
                      '), url(' +
                      defaultSrc +
                      ')'
                  }"
                  [ngClass]="{
                    'image-container__empty': !getImageUrl(contact.userId)
                  }"></div>
                <div class="flex flex-col">
                  <div
                    class="font-bold line-clamp-1 break-all text-ellipsis text-sm md:text-base">
                    {{ contact.name }}
                  </div>
                  @if (contact.jobFunction) {
                    <div
                      class="line-clamp-1 break-all text-ellipsis text-sm md:text-base">
                      {{ contact.jobFunction }}
                    </div>
                  }
                  @if (contact.phone) {
                    <div
                      class="line-clamp-1 break-all text-ellipsis text-sm md:text-base">
                      {{ contact.phone }}
                    </div>
                  }
                </div>
              </div>
            </a>
            <button
              mat-icon-button
              class="fab-button-color scale-[0.85] cursor-pointer self-center"
              (click)="openChat(contact.userId)">
              <mat-icon class="material-symbols-rounded scale-[1.2]">
                forum
              </mat-icon>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Ansprechpersonen"
    subtitle="Kontaktdaten verwalten"
    description="Füge hier die schulischen Ansprechpersonen hinzu."
    [link]="permissionLink"
    [fullWidthAlways]="true" />
}
