import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from '../../../../services/school.service';
import { School } from '../../../../types/school';
import { convertRichTextToPlainText } from 'src/app/core/richText';

@Component({
  selector: 'app-school-about-form',
  templateUrl: './school-about-form.component.html',
  styleUrls: ['./school-about-form.component.scss'],
})
export class SchoolAboutFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;

  highlightSuggestions = [
    'Schule für alle',
    'Tagesschule',
    'QUIMS',
    'Radix-Schule',
    'Mosaikschule',
    'Schulvisite',
    'BNE-Label',
    'Profolio',
    'Digital',
    'Innovativ',
    'Eingespieltes Team',
    'Junges Team',
    'Ländlich',
    'Agglomeration',
    'Städtisch',
  ];

  placeholder = '';

  form = this.fb.group({
    about: ['', [Validators.required]],
    aboutHighlights: [],
  });

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = this.schoolService.getById(this.id);
    this.school$.subscribe(school => {
      if (school.isRoot) {
        this.placeholder =
          'Schreibe authentisch zum Beispiel von eurer Schulgeschichte, euren Werten oder Visionen. So eroberst du die Sympathien deiner Profilbesucher.';
      } else {
        this.placeholder =
          'Jede Schule hat ihre ganz eigenen Strukturen, wie auch ihre eigene Geschichte und Kultur. Vielleicht habt ihr ja auch eine Vision. Mit solchen Informationen gewinnst du die Aufmerksamkeit deiner Profilbesucher.';
      }
    });
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.form.setValue({
        about: school.about_html || '',
        aboutHighlights: school.aboutHighlights || [],
      } as any);
    });
  }

  save() {
    const { about } = this.form.getRawValue();
    const payload = {
      about: convertRichTextToPlainText(about) || '',
      about_html: about || '',
      aboutHighlights: this.form.get('aboutHighlights')?.value || [],
    };

    this.schoolService.update(this.id, payload).subscribe(async () => {
      await this.router.navigate(['schools', this.id]);
    });
  }
}
