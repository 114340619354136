@if (props.title) {
  <div mat-dialog-title class="text-black text-3xl font-bold">
    {{ props.title }}
  </div>
}
<div
  mat-dialog-content
  class="text-base text-black"
  [innerHTML]="props.text"></div>

<div mat-dialog-actions>
  @if (!props.onlyAcceptButton) {
    <button
      mat-button
      mat-dialog-close
      [color]="props.onlyAcceptButton ? 'warn' : null">
      {{ props.cancelText || 'Abbrechen' }}
    </button>
  }
  <button mat-button (click)="accept()" [color]="props.buttonColor || 'warn'">
    {{ props.confirmText || 'Ok' }}
  </button>
</div>
