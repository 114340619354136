import { Component, Input, OnInit } from '@angular/core';
import { getPublicFileUrl } from '../../../../core/helpers';
import { Job } from '../../../../types/job';
import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-job-header',
  templateUrl: './job-header.component.html',
  styleUrls: ['./job-header.component.scss'],
})
export class JobHeaderComponent {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  constructor(public jobService: JobService) {}

  getAvatarUrl(job: Job) {
    return getPublicFileUrl('schools', job.schoolId, 'avatar');
  }

  getBackgroundUrl(job: Job) {
    return getPublicFileUrl('schools', job.schoolId, 'backgroundImage');
  }

  getAddress(job: Job) {
    return job.schoolAddress?.city === job.schoolAddress?.municipality
      ? job.schoolAddress?.city
      : `${job.schoolAddress?.city}, ${job.schoolAddress?.municipality}`;
  }

  getJobLevels(job: Job) {
    return this.jobService.getLevelsTitle(
      job.schoolLevels || [],
      job.schoolLevelDescriptions || []
    );
  }

  getRequirements(job: Job) {
    const requirements = [];
    if (job.requirements?.baseYear) requirements.push('Basisjahr');
    if (job.requirements?.diplom) requirements.push('Diplom');
    if (job.requirements?.student) requirements.push('in Ausbildung');
    return requirements.join(', ');
  }

  getScheduleText(job: Job) {
    const texts = [];
    if (
      job.activityRangeInPercentage?.from &&
      job.activityRangeInPercentage?.to
    ) {
      texts.push(
        job.activityRangeInPercentage.from === job.activityRangeInPercentage.to
          ? `Pensum: ${job.activityRangeInPercentage.from} %`
          : `Pensum: ${job.activityRangeInPercentage.from} % bis ${job.activityRangeInPercentage.to} %`
      );
    }
    if (job.activityRangeInHours?.from && job.activityRangeInHours?.to) {
      texts.push(
        job.activityRangeInHours.from === job.activityRangeInHours.to
          ? `Lektionen: ${job.activityRangeInHours.from}`
          : `Lektionen: ${job.activityRangeInHours.from} bis ${job.activityRangeInHours.to}`
      );
    }

    return texts.join(' | ');
  }

  hasSubjects(job: Job) {
    return !!(job.subjects && job.subjects.length > 0);
  }

  hasRequirements(job: Job) {
    if (!job.requirements) {
      return false;
    }

    return job.requirements.diplom || job.requirements.student;
  }
}
