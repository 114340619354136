<div
  class="flex flex-col h-full p-6 md:p-0 2xl:p-6 md:w-[88px] 2xl:w-auto md:items-center 2xl:items-start">
  <div
    class="h-auto w-32 md:w-16 2xl:w-32 pb-16 block md:flex 2xl:block justify-center pt-0 md:pt-6 2xl:pt-0">
    <a routerLink="/" (click)="navigate()">
      <img src="./assets/logo.svg" alt="Logo" class="md:hidden 2xl:block" />
      <img
        alt="Logo"
        src="./assets/xslogo.svg"
        class="hidden md:block 2xl:hidden h-6 w-6" />
    </a>
  </div>
  <div>
    <app-round-image
      (click)="navigate()"
      size="60"
      [title]="profile?.name"
      [description]="getJobTitle()"
      [src]="getAvatarUrl()"
      [routerLink]="['/me', profile?.id]"
      class="md:hidden 2xl:block cursor-pointer"></app-round-image>
    <img
      alt="Profile Avatar"
      [routerLink]="['/me', profile?.id]"
      [src]="getAvatarUrl()"
      class="hidden cursor-pointer md:block 2xl:hidden w-14 h-14 rounded-full"
      [matTooltip]="profile!.name"
      matTooltipPosition="after"
      [matTooltipDisabled]="showTooltips()" />
  </div>
  <div class="pt-6 h-full 2xl:w-full">
    <mat-nav-list>
      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Startseite"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex self-center">
          <mat-icon class="material-symbols-rounded">home</mat-icon>
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Startseite
          </div>
        </div>
      </a>
      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/schools"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Schulen"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex self-center">
          <mat-icon class="material-symbols-rounded">school</mat-icon>
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Schulen
          </div>
        </div>
      </a>

      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/profile"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Fachkräfte"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex self-center">
          <mat-icon class="material-symbols-rounded">group</mat-icon>
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Fachkräfte
          </div>
        </div>
      </a>

      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/chat"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Nachrichten"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex justify-between items-center">
          <div class="flex self-center items-center">
            <mat-icon class="material-symbols-rounded">forum</mat-icon>
            <div
              class="pl-4 leading-none mt-1 block md:hidden 2xl:block font-bold">
              Nachrichten
            </div>
          </div>
          @if (unreadMessageCount > 0) {
            <span class="text-sm font-medium text-bn-primary-blue pr-2">
              {{ unreadMessageCount }}
            </span>
          }
        </div>
      </a>

      <div class="pt-4 pb-4">
        <mat-divider></mat-divider>
      </div>

      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/jobs"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Stellen"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex self-center">
          <mat-icon class="material-symbols-rounded">work</mat-icon>
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Stellen
          </div>
        </div>
      </a>

      <mat-nav-list #searchagent class="relative">
        <a
          mat-list-item
          (click)="navigate()"
          routerLink="/search-agent"
          routerLinkActive="active"
          class="h-14 md:w-14 2xl:w-auto highlighted"
          matTooltip="Suchagent"
          matTooltipPosition="after"
          [matTooltipDisabled]="showTooltips()">
          <div class="flex justify-between items-center">
            <div class="flex self-center items-center">
              <mat-icon class="material-symbols-rounded highlighted-icon">
                manage_search
              </mat-icon>
              <div
                class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold highlighted-icon">
                Suchagent
              </div>
              <app-highlight-bubble
                text="Neu!"
                class="block md:hidden 2xl:block pr-4 absolute z-30 right-0" />
            </div>
          </div>
        </a>
        <app-highlight-bubble
          text="Neu!"
          class="hidden md:block 2xl:hidden absolute z-30 top-1 -right-4" />
      </mat-nav-list>

      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/applications/my"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Bewerbungen"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex self-center">
          <mat-icon class="material-symbols-rounded">mail</mat-icon>
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Bewerbungen
          </div>
        </div>
      </a>
    </mat-nav-list>

    @if (isSchoolAdmin) {
      <div class="pt-4 pb-4">
        <mat-divider></mat-divider>
      </div>
      <mat-nav-list>
        <a
          mat-list-item
          (click)="navigate()"
          routerLink="/my-schools"
          routerLinkActive="active"
          class="h-14 md:w-14 2xl:w-auto"
          matTooltip="Meine Schule"
          matTooltipPosition="after"
          [matTooltipDisabled]="showTooltips()">
          <div class="flex self-center">
            <mat-icon class="material-symbols-rounded">grid_view</mat-icon>
            <div
              class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
              Meine Schule
            </div>
          </div>
        </a>
      </mat-nav-list>
    }
  </div>

  <div class="2xl:w-full">
    <mat-nav-list class="end">
      <a
        mat-list-item
        (click)="navigate()"
        routerLink="/settings"
        routerLinkActive="active"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Einstellungen"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()">
        <div class="flex self-center">
          <mat-icon class="material-symbols-rounded">settings</mat-icon>
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Einstellungen
          </div>
        </div>
      </a>
      <button
        mat-list-item
        (click)="logout()"
        class="h-14 md:w-14 2xl:w-auto"
        matTooltip="Ausloggen"
        matTooltipPosition="after"
        [matTooltipDisabled]="showTooltips()"
        [disabled]="logoutInProgress">
        <div class="flex self-center">
          @if (logoutInProgress) {
            <mat-spinner diameter="24" />
          } @else {
            <mat-icon class="material-symbols-rounded">logout</mat-icon>
          }
          <div
            class="pl-4 leading-none mt-1.5 block md:hidden 2xl:block font-bold">
            Ausloggen
          </div>
        </div>
      </button>
    </mat-nav-list>
  </div>
</div>
