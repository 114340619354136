<div
  class="flex flex-col border-box gap-y-5 p-5 cursor-pointer hover:border-transparent card">
  <div class="pl-2">
    <mat-icon class="scale-[1.75]" [svgIcon]="icon" />
  </div>
  <div class="flex flex-col">
    <h3 class="text-xl font-bold text-bn-primary-blue leading-3">
      {{ title }}
    </h3>
    <p class="text-sm text-bn-grey-font">{{ description }}</p>
  </div>
</div>
