import { inject, Injectable } from '@angular/core';
import {
  ChannelService,
  ChatClientService,
  DefaultStreamChatGenerics,
} from 'stream-chat-angular';
import { from, map, Observable } from 'rxjs';
import type {
  APIResponse,
  BlockUserAPIResponse,
  Channel,
  ChannelResponse,
  DeleteChannelAPIResponse,
  MuteChannelAPIResponse,
  UpdateChannelAPIResponse,
} from 'stream-chat';

import { StreamUserService } from './stream-user.service';
import type { StreamUser } from '../../../app/types/chat';

@Injectable({
  providedIn: 'root',
})
export class StreamChannelService {
  private readonly chatService = inject(ChatClientService);
  private readonly channelService = inject(ChannelService);
  private readonly streamUserService = inject(StreamUserService);

  public async activateChannelById(id?: string | null): Promise<void> {
    if (id) {
      const uid = this.streamUserService.currentUser().uid;

      /** Handle hidden channel, hidden channel can only queried by hidden: true **/
      const hidden = await this.chatService.chatClient.queryChannels({
        id,
        hidden: true,
        members: { $in: [uid] },
      });

      if (hidden.length === 1) {
        await hidden[0].show();
        return this.channelService.setAsActiveChannel(hidden[0]);
      }

      /** Handle normal channel **/
      const channel = await this.chatService.chatClient.queryChannels({
        id,
        members: { $in: [uid] },
      });
      return this.channelService.setAsActiveChannel(channel[0]);
    }
  }

  public create(
    members: string[],
    name?: string,
    image?: string,
    id?: string
  ): Observable<ChannelResponse<DefaultStreamChatGenerics>> {
    const response = this.chatService.chatClient.channel('messaging', id, {
      members: [...members, this.streamUserService.currentUser().uid],
      name,
      image,
    });
    return from(response.create()).pipe(map(x => x.channel));
  }

  public isGroup(channel?: Channel<DefaultStreamChatGenerics>): boolean {
    return (channel?.data?.member_count ?? 0) > 2;
  }

  public name(
    channel?: Channel<DefaultStreamChatGenerics>
  ): string | undefined {
    if (channel?.data?.name) {
      return channel.data.name;
    }

    if (!!channel && !this.isGroup(channel)) {
      return this.collectMembers(channel)?.[0].name ?? undefined;
    }

    return channel?.id;
  }

  public fallbackImage(
    channel?: Channel<DefaultStreamChatGenerics>
  ): string | undefined {
    if (!this.isGroup(channel)) {
      const other = this.collectMembers(channel)?.[0];
      return !!other && 'image' in other
        ? (other['image'] as string)
        : undefined;
    }
    return channel?.data?.image;
  }

  public member(
    channel?: Channel<DefaultStreamChatGenerics>
  ): StreamUser | null {
    return this.isGroup(channel) ? null : this.collectMembers(channel)?.[0];
  }

  public blockUsers(
    channel?: Channel<DefaultStreamChatGenerics>
  ): Observable<BlockUserAPIResponse[]> {
    const block: Promise<BlockUserAPIResponse>[] = [];
    const users = this.collectMembers(channel)?.map(user => user.id);
    for (const user of users) {
      block.push(this.chatService.chatClient.blockUser(user));
    }
    return from(Promise.all(block));
  }

  public leaveChannel(
    channel: Channel<DefaultStreamChatGenerics>
  ): Observable<UpdateChannelAPIResponse<DefaultStreamChatGenerics>> {
    return from(
      channel.removeMembers([this.streamUserService.currentUser().uid])
    );
  }

  public deleteChannel(
    channel: Channel<DefaultStreamChatGenerics>
  ): Observable<DeleteChannelAPIResponse<DefaultStreamChatGenerics>> {
    return from(channel.delete());
  }

  public hide(
    channel: Channel<DefaultStreamChatGenerics>
  ): Observable<APIResponse> {
    return from(channel.hide(this.streamUserService.currentUser().uid, true));
  }

  public mute(
    channel: Channel<DefaultStreamChatGenerics>
  ): Observable<MuteChannelAPIResponse<DefaultStreamChatGenerics>> {
    return from(channel.mute());
  }

  public isModerator(channel?: Channel<DefaultStreamChatGenerics>): boolean {
    const memberShip =
      channel?.state.members[this.streamUserService.currentUser().uid];

    if (memberShip && memberShip.role) {
      return ['owner', 'moderator'].includes(memberShip.role);
    }

    return false;
  }

  private collectMembers(
    channel?: Channel<DefaultStreamChatGenerics>
  ): StreamUser[] {
    return channel?.state.members
      ? Object.values(channel.state.members)
          .map(m => m.user || { id: m.user_id! })
          .filter(m => m.id !== this.streamUserService.currentUser().uid)
      : [];
  }
}
