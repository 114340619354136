@if (!showEmptyCard && !loading) {
  <div class="relative">
    <app-video-player
      [hls]="school.video?.hls"
      type="video/mp4"></app-video-player>
    @if (isOwner) {
      <button
        (click)="onRemove()"
        class="absolute top-2 right-2 z-[1000]"
        mat-icon-button>
        <mat-icon class="material-symbols-rounded text-white">delete</mat-icon>
      </button>
    }
  </div>
}

@if ((showEmptyCard && this.isOwner) || loading) {
  <div>
    <input
      type="file"
      class="hidden"
      [accept]="acceptTypes"
      (change)="upload($event)"
      #fileUpload />
    <div class="flex flex-col">
      <button
        (click)="!loading ? fileUpload.click() : null"
        class="flex bgfill rounded-2xl md:rounded-3xl p-8 text-base gap-8 h-[468px] justify-center cursor-pointer hover:brightness-75">
        @if (!loading) {
          <div
            class="flex flex-col gap-2 self-center justify-center items-center">
            <mat-icon class="material-symbols-rounded">upload</mat-icon>
            <span class="text-base pt-4">Imagevideo wählen</span>
          </div>
        }
        @if (loading) {
          <div
            class="flex flex-col gap-8 self-center justify-center items-center">
            <mat-spinner></mat-spinner>
            @if (uploadProgress$ | async; as progress) {
              <strong>
                {{ showProgress(progress) }}
              </strong>
            }
          </div>
        }
      </button>
    </div>
  </div>
}
