import { Component } from '@angular/core';
import { Application, ApplicationStatus } from '../../../types/application';
import { ApplicationService } from '../../../services/application.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, EMPTY, ReplaySubject, switchMap } from 'rxjs';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { JobService } from '../../../services/job.service';
import { NotificationService } from '../../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { RejectMessageDialogComponent } from '../../applications/reject-message-dialog/reject-message-dialog.component';

@Component({
  selector: 'app-schools-jobs-applicants',
  templateUrl: './schools-jobs-applicants.component.html',
  styleUrls: ['./schools-jobs-applicants.component.scss'],
})
export class SchoolsJobsApplicantsComponent {
  schoolId?: string;
  jobFunction: string = '';
  applications$: Observable<Application[]> = EMPTY;
  fetch$ = new ReplaySubject(1);
  loadingApplications: string[] = [];

  constructor(
    route: ActivatedRoute,
    jobService: JobService,
    breadcrumbService: BreadcrumbService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private applicationService: ApplicationService
  ) {
    route.paramMap.subscribe(params => {
      this.schoolId = params.get('id') as string;
      const jobId = params.get('jobId') as string;

      this.applications$ = this.fetch$.pipe(
        switchMap(() => applicationService.getByJobId(jobId, this.schoolId!))
      );

      this.reload();

      jobService.getById(jobId).subscribe(job => {
        this.jobFunction = job.function || '';

        breadcrumbService.set([
          { text: 'Meine Schule', link: 'my-schools' },
          { text: job.schoolName || '', link: `schools/${job.schoolId}/jobs` },
          { text: job.function || 'Inserat' },
        ]);
      });
    });
  }

  getByStatus(
    applications: Application[],
    status: (typeof ApplicationStatus)[number]
  ) {
    return applications.filter(a => a.status === status);
  }

  reject(application: Application) {
    this.loadingApplications.push(application.id!);
    this.dialog
      .open(RejectMessageDialogComponent, {
        maxWidth: 'calc(100vw - 16px)',
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((response: { confirm: boolean; text: string }) => {
        if (response?.confirm) {
          this.applicationService
            .reject(application.id!, response.text)
            .subscribe(_ => {
              this.notificationService.success(`${application.name} abgesagt.`);
              this.loadingApplications = this.loadingApplications.filter(
                id => id !== application.id
              );
              this.reload();
            });
        } else {
          this.loadingApplications = this.loadingApplications.filter(
            id => id !== application.id
          );
        }
      });
  }

  reload() {
    this.fetch$.next(1);
  }

  isLoading(application: Application) {
    return this.loadingApplications.includes(application.id!);
  }
}
