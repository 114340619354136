<div class="flex justify-center">
  <div class="flex-col max-w-[950px]">
    <div class="flex">
      <h1 class="font-bold text-4xl">Meine Schule</h1>
    </div>

    <div class="flex flex-col gap-20">
      @for (group of groupedSchools$ | async; track group) {
        <div class="grid grid-cols-12 gap-4">
          <div class="flex flex-col col-span-12">
            <h2 class="text-3xl text-bn-grey-font">{{ getRootName(group) }}</h2>
          </div>

          @if (hasUnpublished(group)) {
            <div class="col-span-12 flex flex-col gap-y-8 pb-4">
              <app-schools-my-progress-card [schoolGroup]="group" />
            </div>
          }

          <a
            class="col-span-12 md:col-span-6"
            [routerLink]="['/schools', group[0].rootId]">
            <app-school-my-item
              icon="custom_edit-school"
              title="Schulprofil"
              description="Schuleinheit und Standorte bearbeiten." />
          </a>
          <a
            class="col-span-12 md:col-span-6"
            [routerLink]="['/my-schools', group[0].rootId, 'jobs']">
            <app-school-my-item
              icon="custom_hr-tool"
              title="HR-Tool"
              description="Inserate und Bewerbungen verwalten." />
          </a>
          <a
            class="col-span-12 md:col-span-6"
            [routerLink]="[
              '/my-schools',
              group[0].rootId,
              'edit',
              'permissions'
            ]">
            <app-school-my-item
              icon="custom_edit-admin"
              title="Admins"
              description="Berechtigungen für deine Schule verwalten." />
          </a>
          <a
            class="col-span-12 md:col-span-6"
            [routerLink]="['/schools', group[0].rootId, 'onboarding']">
            <app-school-my-item
              icon="custom_edit-locations"
              title="Standorte verwalten"
              description="Anzahl und Reihenfolge der Schulstandorte anpassen." />
          </a>
        </div>
      }
    </div>
  </div>
</div>
