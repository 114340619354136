export const environment = {
  name: 'dev',
  useEmulators: false,
  production: false,
  homepageBaseUrl: 'https://stage.bildigsnetz.ch',
  storageBaseUrl:
    'https://firebasestorage.googleapis.com/v0/b/bildigsnetz-stage.appspot.com/o',
  firebase: {
    apiKey: 'AIzaSyAYMO2EB8jnLiSCGbBsWsfGcAXlt6rjQhk',
    authDomain: 'bildigsnetz-stage.firebaseapp.com',
    projectId: 'bildigsnetz-stage',
    storageBucket: 'bildigsnetz-stage.appspot.com',
    messagingSenderId: '846925404833',
    appId: '1:846925404833:web:6dc21171a642a8bff12ee0',
    locationId: 'europe-west3',
    measurementId: 'G-B63B94VCVE',
  },
  algolia: {
    appId: '2QUDJUAQAH',
    api_key: '529558d1eaf9e726f4856312aa7e41dd',
  },
  gmaps: {
    apiKey: 'AIzaSyAYMO2EB8jnLiSCGbBsWsfGcAXlt6rjQhk',
  },
  sentry: {
    dsn: 'https://0363883f163e5d59001514ae652ab04a@o4505048277057536.ingest.sentry.io/4505742792130560',
  },
  edulog: {
    authority: 'https://go.int.edulog.ch/auth/realms/edulog',
    redirectUrl: 'https://stage.app.bildigsnetz.ch/auth-edulog-callback',
    postLoginRoute: '/auth-edulog-callback',
    postLogoutRedirectUri: window.location.origin,
    clientId: 'bildigsnetz',
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: false,
    useRefreshToken: false,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    customParamsCodeRequest: {
      client_secret: 'CWGhw9Vzlxc84PoYvO1zGtU2eTZ8Ie60',
    },
  },
  disableVideoDeletion: true,
  stream: {
    chat: {
      apiKey: '8vrfh4f4uvy3',
    },
    group: {
      fallbackAvatarImage:
        'https://stage.app.bildigsnetz.ch/assets/chat/group-chat-fallback.svg',
    },
  },
};
